import { createReducer } from 'store/utils';
import { ACTIONS } from './actions';
import { startOfDay, endOfDay, subMonths } from 'date-fns';

import { ORDER_STATUS_ARRAY } from 'lib/data/constants';
const VALID_ORDERS = ORDER_STATUS_ARRAY.map(item => item.value);

const initialState = {
  orderIndexFilter: {
    status: VALID_ORDERS,
    dateFilter: 'lastMonth',
    dateGte: startOfDay(subMonths(new Date(), 1)),
    dateLte: endOfDay(new Date()),
    orderTotalMin: '',
    orderTotalMax: '',
    orderQuantityMin: '',
    orderQuantityMax: '',
    shipToCity: '',
    shipToState: '',
  },
  exportLoading: false,
  importLoading: false,
  upload: {
    keyId: null,
  },
};

const orderIndexFilter = (state, action) => {
  return {
    ...state,
    orderIndexFilter: action.payload,
  };
};

const exportLoading = (state, action) => {
  return {
    ...state,
    exportLoading: action.payload,
  };
};

const setOrderImportKey = (state, { keyId }) => {
  return {
    ...state,
    upload: {
      keyId,
    },
  };
};

export default createReducer(initialState, {
  [ACTIONS.SET_ORDER_INDEX_FILTER]: orderIndexFilter,
  [ACTIONS.SET_EXPORT_LOADING]: exportLoading,
  [ACTIONS.SET_ORDER_IMPORT_KEY]: setOrderImportKey,
});
