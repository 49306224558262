import { createReducer } from 'store/utils';
import { ACTIONS } from './actions';

const initialState = {
  upload: {
    keyId: null,
  },
};

const setInventoryImportKey = (state, { keyId }) => {
  return {
    ...state,
    upload: {
      keyId,
    },
  };
};

export default createReducer(initialState, {
  [ACTIONS.SET_INVENTORY_IMPORT_KEY]: setInventoryImportKey,
});
