import { createReducer } from 'store/utils';
import { ACTIONS } from './actions';

const initialState = {
  channels: [],
};

const setChannels = (state, { channels }) => {
  return {
    ...state,
    channels: channels,
  };
};

export default createReducer(initialState, {
  [ACTIONS.SET_CHANNELS]: setChannels,
});
