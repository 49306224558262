export const SETTINGS_LINK_ID = 5;
export const NETWORKS_LINK_ID = 15;
export const SHIPPING_LINK_ID = 10;

export const navLinks = [
  {
    id: 1,
    order: 10,
    label: 'Orders',
    url: '/orders/orders',
    active: window.location.pathname === '/orders/orders',
  },
  {
    id: 2,
    order: 20,
    label: 'Inventory',
    url: '/orders/inventory',
    active: window.location.pathname === '/orders/inventory',
  },

  {
    id: 3,
    order: 30,
    label: 'Warehouse',
    url: '/orders/warehouse',
    active: window.location.pathname === '/orders/warehouse',
  },
  {
    id: 4,
    order: 40,
    label: 'History',
    url: '/orders/history',
    active: window.location.pathname === '/orders/history',
    isExtra: true,
  },
  {
    id: SETTINGS_LINK_ID,
    order: 50,
    label: 'Settings',
    url: '/orders/settings',
    active: window.location.pathname === '/orders/settings',
    isExtra: true,
  },
];
