import { GlobalStyle } from '@teamfabric/copilot-ui';
import initiateStore from 'store';
import { Provider } from 'react-redux';
import { theme } from 'lib/theme';
import 'lib/theme/fonts.css';
import GlobalStyles from 'lib/theme/globals';
import Toasts from 'modules/Toasts';
import { ThemeProvider } from 'styled-components';
import { FeatureFlagsProvider } from 'hooks/useFeatureFlags/context';
import { AppChildren, AppRoot, AppWrapper } from './styles';
import AppRoutes from './AppRoutes';

const Root = () => {
  const store = initiateStore({});

  return (
    <FeatureFlagsProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <AppRoot>
            <GlobalStyles />
            <AppWrapper className='d-flex w-100'>
              <AppChildren>
                <AppRoutes />
              </AppChildren>
            </AppWrapper>
          </AppRoot>
          <Toasts />
        </ThemeProvider>
      </Provider>
    </FeatureFlagsProvider>
  );
};

export default Root;
