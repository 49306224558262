export const sum = (arr, key) => {
  if (Array.isArray(arr) && arr.length) {
    return arr.reduce((a, b) => a + (b[key] || 0), 0);
  } else {
    return 0;
  }
};

export const sumInTransit = (arr, mainKey, key1, key2) => {
  if (Array.isArray(arr) && arr.length) {
    return arr.reduce(
      (a, b) =>
        a +
        (b && b[mainKey]
          ? b[mainKey].reduce((a, b) => a + (b[key1] - b[key2] || 0), 0)
          : 0),
      0
    );
  } else {
    return 0;
  }
};

export const calculateInStockAvailable = arr => {
  let available = 0;

  if (Array.isArray(arr) && arr.length) {
    arr.forEach(a => {
      const channel = a.channel;
      channel.forEach(c => {
        if (c.allocation.mou.toLowerCase() === 'absolute') {
          available = available + (c.allocation.unit - c.quantityReserved);
        } else if (c.allocation.mou.toLowerCase() === 'percent') {
          available =
            available +
            ((c.allocation.unit / 100) * a.inStock - c.quantityReserved);
        }
      });
    });
  }
  return available;
};

export const calculateOrderSubtotalCost = items => {
  if (Array.isArray(items) && items.length) {
    return items.reduce((a, b) => a + (b.quantity * b.price || 0), 0);
  } else {
    return 0;
  }
};
