import { FeatureFlagStrategy } from './types';
import { mapStageToEnvironmentScope } from './utils';
import getApplicationStage from 'lib/utils/getApplicationStage';

export interface FlagApplicationStrategy {
  (flagStrategy: FeatureFlagStrategy): boolean;
}

const defaultStrategy: FlagApplicationStrategy = (
  flagStrategy: FeatureFlagStrategy
) => {
  const envs = flagStrategy?.scopes?.map(s => s.environment_scope) ?? [];
  return (
    !envs.length ||
    envs?.includes(mapStageToEnvironmentScope(getApplicationStage()))
  );
};

const userIdsStrategy: FlagApplicationStrategy = (
  flagStrategy: FeatureFlagStrategy
) => {
  const isEnvApplicable = defaultStrategy(flagStrategy);
  if (!isEnvApplicable) return isEnvApplicable;
  const applicableAccountIds =
    typeof flagStrategy?.parameters?.userIds === 'string'
      ? flagStrategy?.parameters?.userIds.split(',')
      : [];
  return applicableAccountIds.includes(sessionStorage.getItem('accountId'));
};

export default {
  default: defaultStrategy,
  userWithId: userIdsStrategy,
};
