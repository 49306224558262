export const carrierUrl = ({ carrier, trackingNumber }) => {
  if (carrier === 'Fedex') {
    return `https://www.fedex.com/apps/fedextrack/?action=track&action=track&language=english&cntry_code=us&tracknumbers=${trackingNumber}`;
  } else if (carrier === 'UPS') {
    return `http://wwwapps.ups.com/WebTracking/track?loc=en_US&track.x=Track&trackNums=${trackingNumber}`;
  } else if (carrier === 'DHL') {
    return `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNumber}`;
  } else if (carrier === 'USPS') {
    return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${trackingNumber}`;
  } else if (carrier === 'Ryder') {
    return 'https://ryder.com/supply-chain-management/last-mile-delivery/tracking';
  }

  return null;
};
