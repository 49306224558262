import { createContext, PropsWithChildren } from 'react';
import { FeatureFlagsDictionary } from 'hooks/useFeatureFlags/types';
import useFeatureFlags from 'hooks/useFeatureFlags';

export const FeatureFlagsContext = createContext<{
  isFetching: boolean;
  flags: FeatureFlagsDictionary;
  error: Error;
}>({
  isFetching: false,
  flags: {},
  error: null,
});

export const FeatureFlagsProvider = ({ children }: PropsWithChildren<any>) => {
  const featureFlagContextValue = useFeatureFlags();
  return (
    <FeatureFlagsContext.Provider value={featureFlagContextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
