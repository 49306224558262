import styled from 'styled-components';

export const AppRoot = styled.div`
  height: 100%;
  width: 100%;
  background-color: #f1f2f4;
`;
export const AppWrapper = styled.main`
  height: calc(100vh - 60px);
  background-color: ${props => props.theme.palette.grey.lighter};
  overflow: hidden;
`;

export const AppChildren = styled.div`
  width: calc(100% - 150px);
  height: 100%;
  overflow: auto;
  padding-top: 26px;
  padding-right: 24px;

  & > * {
    height: 100%;
  }

  & > .input__wrapper {
    border-bottom: none;
  }
`;
