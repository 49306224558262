import React from 'react';
import { FeatureFlagsProvider } from 'hooks/useFeatureFlags/context';
import 'lib/theme/fonts.css';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme, CopilotUIProvider } from 'ds4-beta';
import AppRoutes from './AppRoutes';
import initiateStore from 'ds4/store';
import { Provider } from 'react-redux';

const OMS = () => {
  const store = initiateStore({});

  return (
    <Provider store={store}>
      <FeatureFlagsProvider>
        <CopilotUIProvider>
          <ThemeProvider theme={{ theme }}>
            <React.Fragment>
              <GlobalStyle />
              <AppRoutes />
            </React.Fragment>
          </ThemeProvider>
        </CopilotUIProvider>
      </FeatureFlagsProvider>
    </Provider>
  );
};

export default OMS;
