import {
  EnvironmentScope,
  EnvironmentScopeEnum,
  FeatureFlag,
  TransformedFeatureFlag,
} from './types';
import { CONFIG } from '../../general-config';

export const FEATURE_FLAGS_STORAGE_KEY = 'feature_flags' as const;

export const featureFlagsApiCall = async (
  page: number,
  signal: AbortSignal
) => {
  const response = await fetch(
    `https://gitlab.com/api/v4/projects/${CONFIG.GITLAB_PROJECT_ID}/feature_flags?scope=enabled&per_page=100&order_by=id&sort=asc&page=${page}`,
    {
      signal,
      headers: {
        'Private-Token': CONFIG.GITLAB_TOKEN,
      },
    }
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
};

export const transformFeatureFlagResponse = (
  featureFlag: FeatureFlag
): TransformedFeatureFlag => {
  return {
    name: featureFlag.name,
    active: featureFlag.active,
    scopes: featureFlag.scopes,
    strategies: featureFlag?.strategies ?? [],
  };
};

export const buildFeatureFlagDictionary = (
  transformedFeatureFlags: TransformedFeatureFlag[]
) => {
  return Object.fromEntries(
    transformedFeatureFlags.map(flag => [flag.name, flag])
  );
};

export const mapStageToEnvironmentScope = (stage: string): EnvironmentScope => {
  switch (stage) {
    case 'prod01':
    case 'prod02':
    case 'live': {
      return EnvironmentScopeEnum.production;
    }
    case 'stg02': {
      return EnvironmentScopeEnum.staging;
    }
    case 'uat':
    case 'sandbox': {
      return EnvironmentScopeEnum.sandbox;
    }
    case 'testing':
    case 'dev02': {
      return EnvironmentScopeEnum.development;
    }
    default: {
      return stage;
    }
  }
};
