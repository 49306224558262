import styled from 'styled-components';
import { theme } from '@teamfabric/copilot-ui';

export const StyledEmptyState = styled.div`
  margin-top: 111px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .header {
    .heading {
      ${theme.typography.kicker};
      color: ${theme.palette.brand.primary.gray};
      margin: 0;
    }
  }

  .body {
    margin-top: 46px;
    .message {
      width: 471px;
      ${theme.typography.subtitle2};
      color: ${theme.palette.brand.primary.charcoal};
    }
  }

  .footer {
    margin-top: 80px;
    .footer-text {
      width: 224px;
      ${theme.typography.h6};
      color: ${theme.palette.brand.primary.charcoal};
      margin: 0 auto;

      .link {
        color: ${theme.palette.ui.cta.blue};
        cursor: pointer;
      }
    }
  }
`;

export const StyledHistoryTab = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
`;

export const StyledHistoryMenuTab = styled.div`
  display: flex;
  height: 25px;
  .history-menu-tab {
    font-family: Gilroy;
    color: #121213;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-right: 30px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  .active {
    border-bottom: 2px solid #0d62ff;
  }
`;
export const StyledHistoryTable = styled.div`
  width: 100%;
  min-width: 100%;
  margin-top: 50px;

  .error-block {
    display: flex;
    width: 100%;
    .error-block-message {
      display: flex;
      justify-content: center;
      font-size: 24px;
      font-family: Gilroy-SemiBold;
      margin-top: 100px;
      width: 100%;
    }
  }
  .history-row {
    font-family: Gilroy-Medium;

    td {
      font-family: Gilroy-Medium;
    }
  }

  .custom-cell {
    text-transform: capitalize;
    box-sizing: border-box;
  }
  .custom-column {
    width: 150px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .loading {
    width: 100%;
    height: 100%;
  }
  .error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    .first {
      margin-bottom: 32px;
      font-size: 12px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.9px;
      line-height: 14px;
      font-style: normal;
      text-transform: uppercase;
      color: rgb(115, 127, 143);
    }
    .second {
      margin: 0px auto;
      font-size: 24px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0px;
      line-height: 29px;
      font-style: normal;
      color: rgb(18, 18, 19);
    }
    .third {
      margin-top: 46px;
    }
  }
`;
export const StyledCell = styled.div`
  max-width: 350px;
  box-sizing: border-box;
  font-family: Gilroy-Medium;
`;
