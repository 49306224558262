import { useEffect, useMemo, useRef, useState } from 'react';
import { UnleashClient } from 'unleash-proxy-client';

import useIsMounted from 'hooks/useIsMounted';
import { FeatureFlagApiResponse, FeatureFlagsDictionary } from './types';
import { FeatureFlagsProvider, FeatureFlagsContext } from './context';
import {
  mapStageToEnvironmentScope,
  buildFeatureFlagDictionary,
  FEATURE_FLAGS_STORAGE_KEY,
  featureFlagsApiCall,
  transformFeatureFlagResponse,
} from './utils';
import getApplicationStage from 'lib/utils/getApplicationStage';

import { safeJsonParse } from 'lib/utils/safeJsonParse';

const fetchFirstPage = async (signal: AbortSignal) => {
  const firstPage = await featureFlagsApiCall(1, signal);
  const currentPage = parseInt(firstPage.headers.get('x-page') ?? '1', 10);
  const totalPages = parseInt(
    firstPage.headers.get('x-total-pages') ?? '1',
    10
  );
  return { firstPage, currentPage, totalPages };
};

export let unleash: UnleashClient = {
  isEnabled: () => false,
  getVariant: () => {},
} as unknown as UnleashClient;
const useFeatureFlags = () => {
  const initialValue = safeJsonParse(
    localStorage.getItem(FEATURE_FLAGS_STORAGE_KEY),
    { isFetching: false, flags: {} }
  );
  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<Error>(null);
  const [flags, setFlags] = useState<FeatureFlagsDictionary>(
    initialValue?.flags ?? {}
  );
  const abortControllerRef = useRef(new AbortController());
  useEffect(() => {
    localStorage.setItem(
      FEATURE_FLAGS_STORAGE_KEY,
      JSON.stringify({
        isFetching: true,
        flags,
      })
    );
  }, [isFetching, flags]);
  useEffect(() => {
    (async () => {
      const pagedRequests: Array<Response | Promise<Response>> = [];
      setIsFetching(true);
      try {
        const { firstPage, currentPage, totalPages } = await fetchFirstPage(
          abortControllerRef.current.signal
        );
        pagedRequests[Math.max(0, currentPage - 1)] = firstPage;
        for (let nextPage = 2; nextPage <= totalPages; nextPage++) {
          pagedRequests[Math.max(0, nextPage - 1)] = featureFlagsApiCall(
            nextPage,
            abortControllerRef.current.signal
          );
        }
        const awaitedPageRequests = await Promise.all(pagedRequests);
        const pagedResponses = await Promise.all(
          awaitedPageRequests.map(response => {
            return response.json() as Promise<FeatureFlagApiResponse>;
          })
        );
        if (isMounted()) {
          const transformedFeatureFlags = pagedResponses
            .flat()
            .map(transformFeatureFlagResponse);
          const featureFlagsDictionary = buildFeatureFlagDictionary(
            transformedFeatureFlags
          );
          setFlags(featureFlagsDictionary);
        }
      } catch (e) {
        if (e?.name !== 'AbortError') {
          isMounted() && setError(e);
        }
      } finally {
        isMounted() && setIsFetching(false);
      }
    })();
    return () => abortControllerRef.current.abort();
  }, [isMounted]);

  const [isUnleashLoading, setIsUnleashLoading] = useState<boolean>(true);

  useEffect(() => {
    setupUnleash();
    unleash.once('ready', () => {
      setIsUnleashLoading(false);
    });

    return () => unleash.stop();
  }, []);

  return useMemo(
    () => ({ flags, isFetching: isFetching || isUnleashLoading, error }),
    [flags, isFetching, isUnleashLoading, error]
  );
};

const UNLEASH_DOMAIN_MAP = {
  development: 'dev',
  staging: 'stage',
  sandbox: 'sandbox',
  production: 'prod',
};

export const setupUnleash = () => {
  const userId = sessionStorage.getItem('accountId');

  // unleash client
  unleash = new UnleashClient({
    url: `https://${
      UNLEASH_DOMAIN_MAP[process.env.ENV_DOMAIN] || 'stage'
    }.foundation.feature.fabric.inc/proxy`, //'https://us.app.unleash-hosted.com/uscc1008/',
    clientKey:
      process.env.FEATURE_FLAG_CLIENT_KEY ?? 'copilot-foundations-stage',
    appName: 'copilot_oms',
    environment: mapStageToEnvironmentScope(getApplicationStage()),
  });
  unleash.start();
  unleash.updateContext({ userId });
};

export { FeatureFlagsProvider, FeatureFlagsContext };
export default useFeatureFlags;
