import { useNavigate } from 'react-router-dom';

export const isFunction = fn => typeof fn === 'function';

export const navigateBack = (location, backAmount) => {
  const navigate = useNavigate();
  navigate(location.href?.split('/').slice(0, -backAmount).join('/')); // m.tkaczyk 12.04.22y: navigate('..') do not work for us when later we wanto to switch between tabs, a little clucky TODO: make routing more resilient
};

export const downloadFile = url => {
  let link = document.createElement('a');
  link.href = url;
  link.download = true;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
