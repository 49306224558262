export default {
  palette: {
    error: {
      main: '#F24659',
    },
    common: {
      black: '#000',
      white: '#fff',
      dark: '#0C053D', //Russian-violet-dark
      theme: '#374CCC', //cerculean-blue
      gray: '#58687A',
      bg: '#f1f2f4',
    },

    grey: {
      darker: '#58687A', //Charcoal - Table header Text
      dark: '#8994A3', //Roman Silver - Side Navigation links that are enabled
      main: '#C4C9D1', //Lavender Grey - Text Field Outlines
      light: '#E6E8EB', //Platinu - Seperation Lines
      lighter: '#F3F3F5', //Cultured - Container background
    },
    border: {
      input: '#C4C9D1', //Lavender Grey - Text Field Outlines
      main: '#E6E8EB', //Platinum - Seperation Lines
      error: '#F24659',
      dark: '#333333',
    },
    bullets: {
      green: '#3dcc79',
      orange: '#f8873a',
      yellow: '#f4c711',
      blue: '#0091ff',
      red: '#D00707',
    },
    others: {
      toggleGreen: '#32be6b',
      statusGreen: 'rgba(61, 204, 121, 0.1)',
      statusOrange: 'rgba(248, 135, 58, 0.1)',
      statusYellow: 'rgba(244, 199, 17, 0.1)',
      statusGrey: 'rgba(146, 146, 146, 0.1)',
    },
    toast: {
      success: {
        main: '#3dcc79',
        icon: '#59d38c',
      },
      fail: {
        main: '#b10000',
        icon: '#bc2626',
      },
    },
    backdrop: {
      main: 'rgba(0, 0, 0, 0.35)',
    },
    text: {
      theme: '#374CCC',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    fontFamily: `'Gilroy', 'Gilroy-Medium', 'Gilroy-SemiBold', 'Gilroy-Bold', 'IBM Plex Sans', 'Roboto'`,
    fontSize: 16,
    fonts: {
      main: 'Gilroy',
      secondary: 'IBM Plex Sans',
    },
    h1: {
      fontSize: '18px',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    h2: {
      fontSize: '14px',
      fontWeight: '700',
      fontStyle: 'normal',
    },
    h3: {
      fontSize: '14px',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    h4: {
      fontSize: '14px',
      fontWeight: '500',
      fontStyle: 'normal',
    },
    label: {
      fontSize: '12px',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    body: {
      fontSize: '14px',
      fontWeight: '500',
      fontStyle: 'normal',
    },
  },
  heights: {
    header: '56px',
  },
  widths: {
    sidebar: '270px',
  },
  shadows: {
    main: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    secondary:
      '0 8px 12px -4px rgba(130, 136, 148, 0.48), 0 0 4px 0 rgba(141, 141, 148, 0.32), 0 0 2px 0 rgba(141, 141, 148, 0.24)',
    menus:
      'rgba(141, 141, 148, 0.28) 0px 8px 16px -4px, rgba(141, 141, 148, 0.16) 0px 0px 4px 0px;',
    calendar:
      '0 4px 8px -2px rgba(141, 141, 148, 0.6), 0 0 2px 0 rgba(141, 141, 148, 0.4)',
  },
  zIndex: {
    context: 800,
    hoverOver: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
};
