import { createReducer } from 'store/utils';
import { ACTIONS } from './actions';

const initialState = {
  orderDetailsLoading: true,
  orderDetails: {},
  orderCancel: {},
};

const getOrderDetailsById = (state, action) => {
  return {
    ...state,
    orderDetails: action.payload,
  };
};

const clearOrderDetails = () => {
  return initialState;
};

const getOrderLoadingStatus = (state, action) => {
  return {
    ...state,
    orderDetailsLoading: action.payload,
  };
};

export default createReducer(initialState, {
  [ACTIONS.GET_ORDER_DETAILS_BY_ID]: getOrderDetailsById,
  [ACTIONS.CLEAR_ORDER_DETAILS]: clearOrderDetails,
  [ACTIONS.ORDER_LOADING]: getOrderLoadingStatus,
});
