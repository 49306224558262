import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import orderDetails from 'modules/OrderDetails/reducer';
import orderIndex from 'modules/OrdersLanding/reducer';
import inventory from 'modules/InventoriesLanding/reducer';
import channels from 'modules/Channels/reducer';
import toasts from 'modules/Toasts/reducer';
import { createReducer } from 'store/utils';

const initateStore = envParams => {
  const env = createReducer(envParams, {});

  const reducer = combineReducers({
    inventory,
    channels,
    orderDetails,
    orderIndex,
    toasts,
    env,
  });

  const store = createStore(
    reducer,
    compose(
      applyMiddleware(thunk),
      typeof window !== undefined && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : x => x
    )
  );

  return store;
};

export const reducers = combineReducers({
  inventory,
  channels,
  orderDetails,
  orderIndex,
  toasts,
  env: {},
});

export default initateStore;
