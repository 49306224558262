import { createGlobalStyle } from 'styled-components';
import { theme } from './';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    font-size: ${theme.typography.fontSize}px;
    height: 100%;
  }
  body {
    margin: 0px;
    font-family: ${theme.typography.fonts.main};
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${theme.palette.common.dark};
    background-color: ${theme.palette.common.bg};
    ${theme.typography.body};
    height: 100%;
    min-height: 100%;
  }

  main {
    height: 100%;
    min-height: 100%;
  }

  #__next{
    height: 100%;
  }

  .reach-router {
    height: 100%;
    width: 100%;
  }

  .side-navigation {
    height: 100%;
    width: 150px;
    padding: 20px;
  }

  .app_right_panel {
    width: 80px;
    height: 100%;
    padding: 20px;
    background: seagreen;
  }

 



  .d-flex {
    display: flex;
  }
  .justify-center {
    justify-content: center;
  
  .justify-start {
      justify-content: flex-start;
  }}
  .align-center{
    align-items: center;
  }

  .h-100{
    height: 100%
  }
  .w-100 {
    width: 100%;
  }
  
  .mr-20 {
    margin-right: 20px;
  }

  .ml-20 {
    margin-left: 20px;
  }
  .pr-20 {
    padding-right: 20px;
  }
  .pl-20 {
    padding-left: 20px;
  }
  .p-20 {
    padding: 20px;
  }
  .p30 {
    padding: 30px;
  }
  .p-10 {
    padding: 10px;
  }
  .m-20 {
    margin: 20px;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .overflow-auto {
    overflow: auto;
  }
  .error {
    ${theme.typography.body};
    color: ${theme.palette.error.main};
    margin: 4px 0;
  }
  .error-colored {
    color: ${theme.palette.error.main};
  }
  a, a:visited {
    text-decoration: none;
  }
  .inactive {
    color: ${theme.palette.grey.main}; 
  }
  .red-bullet:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${theme.palette.bullets.red};
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  .green-bullet:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${theme.palette.bullets.green};
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  .grey-bullet:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${theme.palette.grey.main};
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  .orange-bullet:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${theme.palette.bullets.orange};
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  .yellow-bullet:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${theme.palette.bullets.yellow};
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  .blue-bullet:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${theme.palette.bullets.blue};
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  .half-green-bullet:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${theme.palette.bullets.green};
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  .half-blue-bullet:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${theme.palette.bullets.blue};
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
`;
export default GlobalStyles;
