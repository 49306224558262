export const ACTIONS = {
  SET_ORDER_INDEX_FILTER: 'set_order_index_filter',
  SET_EXPORT_LOADING: 'set_export_loading',
  SET_EXPORT_DATA: 'set_export_data',
  SET_ORDER_IMPORT_KEY: 'set_order_import_key',
  SET_IMPORT_LOADING: 'set_import_loading',
};
import { TOAST_ACTIONS } from 'modules/Toasts/actions';
import {
  getOrderExportKeyId,
  getExportLog,
  getExportFile,
} from 'service/api/orders';
import get from 'lodash/get';

import { downloadFile } from 'lib/utils';

export const saveOrderIndexFilter = filterData => {
  return { type: ACTIONS.SET_ORDER_INDEX_FILTER, payload: filterData };
};

export const setExportLoading = loadingState => {
  return { type: ACTIONS.SET_EXPORT_LOADING, payload: loadingState };
};

/**
 *
 * @param {String} keyId key id from response to be used for polling
 * @param {Function} pollingCallback API to call for polling
 * @param {String} type import or export
 */

const pollKeyId = (keyId, pollingCallback) => dispatch => {
  const intervalId = setInterval(async () => {
    let { data } = await pollingCallback(keyId);

    if (data && data.status === 'FINISHED') {
      stopPolling(intervalId);
      //fetch URLS and download files
      dispatch(downloadS3File(keyId));
    }
    if (data && data.status === 'SERVER_ERROR') {
      stopPolling(intervalId);
      setExportLoading(false);

      dispatch({
        type: TOAST_ACTIONS.ADD_TOAST,
        payload: {
          type: 'ERROR',
          message: `Error downloading file`,
          duration: 2000,
        },
      });
    }
  }, 5000);
  setTimeout(() => {
    stopPolling(intervalId);
  }, 400000);
};

const stopPolling = id => {
  clearInterval(id);
};

const downloadS3File = keyId => async dispatch => {
  try {
    const { data } = await getExportFile(keyId);
    if (data && data.downloadUrls.length) {
      //ON succcess - Dispatch toast "Starting to download file..."
      dispatch({
        type: TOAST_ACTIONS.ADD_TOAST,
        payload: {
          type: 'SUCCESS',
          message: 'Downloading File',
          duration: 1000,
        },
      });
      //single file download
      if (data.downloadUrls.length === 1) {
        const url = data.downloadUrls[0];
        const { status, value } = url;
        if (status === 'fulfilled') {
          downloadFile(value);
        }
        return dispatch(setExportLoading(false));
      }
      //multiple file
      data.downloadUrls.map(file => {
        if (file.status === 'fulfilled') {
          window.open(file.value);
        }
      });
      dispatch(setExportLoading(false));
    }
  } catch (error) {
    dispatch({
      type: TOAST_ACTIONS.ADD_TOAST,
      payload: {
        type: 'ERROR',
        message: 'Error downloading file',
        duration: 2000,
      },
    });
    dispatch(setExportLoading(false));
  }
};

export const generateOrdersCSV = orderFilters => async dispatch => {
  //Dispatch toast "Starting to generate csv file"
  dispatch({
    type: TOAST_ACTIONS.ADD_TOAST,
    payload: {
      type: 'SUCCESS',
      message: 'Preparing to Download File',
      duration: 1000,
    },
  });

  //Dispatch SET_EXPORT_LOADING true
  dispatch(setExportLoading(true));
  //API call to copilot with ordersFilter payload to get URL Links
  try {
    const { data } = await getOrderExportKeyId(orderFilters);
    // get the key id
    const keyId = data._id;
    // poll for key id
    dispatch(pollKeyId(keyId, getExportLog));
  } catch (error) {
    //Will toast exception errors, either too many or too few errors
    const status = error?.response?.status;
    const message =
      status === 400
        ? get(error, 'response.data.message', 'Error exporting errors')
        : 'Error exporting orders';
    dispatch({
      type: TOAST_ACTIONS.ADD_TOAST,
      payload: {
        type: 'ERROR',
        message: message,
        duration: 2000,
      },
    });
    dispatch(setExportLoading(false));
  }
};

export const setOrderImportKey = keyId => {
  return {
    type: ACTIONS.SET_ORDER_IMPORT_KEY,
    keyId,
  };
};
