import get from 'lodash/get';
import {
  getOrderByIdApi,
  addOrderShipmentApi,
  cancelOrder,
  returnOrder,
} from 'service/api/orders';
import { carrierUrl } from 'lib/utils/carrierUrl';
import { mapOrderData } from 'lib/utils/map';

import { TOAST_ACTIONS } from 'modules/Toasts/actions';

export const ACTIONS = {
  GET_ORDER_DETAILS_BY_ID: 'get_order_details_by_id',
  CLEAR_ORDER_DETAILS: 'clear_order_details',
  ORDER_LOADING: 'order_loading',
};

export const getOrderDetailsById = (orderId, channels) => async dispatch => {
  dispatch({ type: ACTIONS.ORDER_LOADING, payload: true });
  try {
    const { data } = await getOrderByIdApi(orderId);
    const myOrderItem = mapOrderData(data.order, data.items, channels);
    dispatch({ type: ACTIONS.GET_ORDER_DETAILS_BY_ID, payload: myOrderItem });
    dispatch({ type: ACTIONS.ORDER_LOADING, payload: false });
  } catch (err) {
    console.error('ERROR IN ORDER FETCH -> ', err);
    const code = get(err, 'request.response.code', undefined);
    //Map validation errors to 404 not found
    if (code === 'REQUEST_VALIDATION') {
      err.response.status = 404;
      err.response.statusText = 'NOT FOUND';
    }
    dispatch({ type: ACTIONS.ORDER_LOADING, payload: false });
    throw err;
  }
};

export const clearOrderDetails = () => async dispatch => {
  dispatch({ type: ACTIONS.CLEAR_ORDER_DETAILS, payload: null });
};

export const addOrderShipment =
  (orderItem, shipToItem, values, channels) => async dispatch => {
    try {
      const orderId = orderItem.orderId;
      const shipments = generateShipmentData({ shipToItem, values });

      const shipmentData = {
        orderId,
        shipments,
      };

      await addOrderShipmentApi(shipmentData);

      const { data } = await getOrderByIdApi(orderId);
      const myOrderItem = mapOrderData(data.order, data.items, channels);

      dispatch({ type: ACTIONS.GET_ORDER_DETAILS_BY_ID, payload: myOrderItem });

      const shipmentCount = shipToItem.shipments.length + 1;

      dispatch({
        type: TOAST_ACTIONS.ADD_TOAST,
        payload: {
          type: 'SUCCESS',
          message: `Shipment ${shipmentCount} created successfully`,
          duration: 3000,
        },
      });
    } catch (err) {
      console.error({ err });

      dispatch({
        type: TOAST_ACTIONS.ADD_TOAST,
        payload: {
          type: 'FAIL',
          message: 'Error creating shipment',
          duration: 3000,
        },
      });
    }
  };

const generateShipmentData = ({ shipToItem, values }) => {
  const lineItems = [];

  Object.keys(values).forEach(key => {
    if (key.startsWith('sku-')) {
      const quantity = parseInt(values[key]);
      lineItems.push({
        lineItemId: parseInt(key.substring(4)),
        quantity,
      });
    }
  });

  const response = [
    {
      shippedDate: values.shippedDate
        ? values.shippedDate
        : new Date().toISOString(),
      shipToId: shipToItem.id,
      shipmentCarrier: values.shipmentProvider,
      shipmentCarrierUrl: carrierUrl({
        carrier: values.shipmentProvider,
        trackingNumber: values.trackingNumber,
      }),
      trackingNumber: values.trackingNumber,
      lineItems,
      shipmentStatus: 'ORDER_SHIPPED',
    },
  ];

  return response;
};

export const cancelOrderApi = (params, channels) => async dispatch => {
  dispatch({ type: ACTIONS.ORDER_LOADING, payload: true });
  try {
    const response = await cancelOrder(params);
    if (response && response.status == 200) {
      dispatch({
        type: TOAST_ACTIONS.ADD_TOAST,
        payload: {
          type: 'SUCCESS',
          message: 'Order cancelled successfully',
          duration: 3000,
        },
      });
      dispatch(getOrderDetailsById(response.data.orderId, channels));
    } else {
      dispatch({ type: ACTIONS.ORDER_LOADING, payload: false });
    }
  } catch (error) {
    const status = get(error, 'request.status', undefined);
    const message =
      status === 400 || status == 404
        ? get(error, 'request.response.message', undefined)
        : 'Error - Unable to cancel order';
    dispatch({
      type: TOAST_ACTIONS.ADD_TOAST,
      payload: {
        type: 'ERROR',
        message: message,
        duration: 3000,
      },
    });
    dispatch({ type: ACTIONS.ORDER_LOADING, payload: false });
    throw error;
  }
};

export const returnOrderApi = (params, channels) => async dispatch => {
  dispatch({ type: ACTIONS.ORDER_LOADING, payload: true });
  try {
    const response = await returnOrder(params);
    if (response && response.status == 200) {
      dispatch({
        type: TOAST_ACTIONS.ADD_TOAST,
        payload: {
          type: 'SUCCESS',
          message: 'Order returned successfully',
          duration: 3000,
        },
      });
      dispatch(getOrderDetailsById(response.data.orderId, channels));
    } else {
      dispatch({ type: ACTIONS.ORDER_LOADING, payload: false });
    }
  } catch (error) {
    const status = get(error, 'request.status', undefined);
    const message =
      status === 400 || status == 404
        ? get(error, 'request.response.message', undefined)
        : 'Error - Unable to return order';
    dispatch({
      type: TOAST_ACTIONS.ADD_TOAST,
      payload: {
        type: 'ERROR',
        message: message,
        duration: 3000,
      },
    });
    dispatch({ type: ACTIONS.ORDER_LOADING, payload: false });
  }
};
