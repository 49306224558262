import API, { API_URL } from 'service/business-layer-client';

export const getOrdersList = async params => {
  return await API.oms.post(API_URL.ORDERS_LIST(), params);
  // return dummyOrdersList
};

export const getOrderByIdApi = async id => {
  const data = await API.oms.get(API_URL.ORDER_BY_ID(id));
  return data;
  // return dummyOrderById
};

export const addOrderShipmentApi = async data => {
  return await API.oms.post(API_URL.ORDER_ADD_SHIPMENT(), data);
};

export const cancelOrder = async params => {
  return await API.oms.patch(API_URL.ORDER_CANCEL(), params);
  // return dummyCancelOrder
};

export const returnOrder = async params => {
  return await API.oms.patch(API_URL.ORDER_RETURN(), params);
};

export const getOrderExportKeyId = async params => {
  return await API.oms.post(API_URL.ORDERS_EXPORT(), params);
  // return dummyExportOrders
};

export const getExportLog = async keyId => {
  return await API.oms.get(API_URL.ORDERS_EXPORT_LOG(keyId));
};

export const getExportFile = async keyId => {
  return await API.oms.get(API_URL.ORDERS_EXPORT_URL(keyId));
};

export const postTrackingIdUpload = async ({ fileName, type }) => {
  return await API.oms.post(API_URL.ORDERS_IMPORT_TRACKING_ID(), {
    fileName,
    type,
  });
};

export const pollOrderUploadStatus = async ({ keyId }) => {
  return await API.oms.get(API_URL.ORDERS_IMPORT_LOG(keyId));
};
