import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getToasts from './selectors';
import { removeToast } from './actions';
import { StyledToast } from './styles';

interface ToastProps {
  type: 'SUCCESS' | 'FAIL';
  message: string;
  id: string;
  duration: number;
}

const typeMap = {
  SUCCESS: 'success',
  FAIL: 'alert',
};

export const Toast = ({ toast }: { toast: ToastProps }) => {
  const dispatch = useDispatch();
  const { type, duration, message, id } = toast;

  const dismissToast = useCallback(
    () => dispatch(removeToast(id) as any),
    [dispatch, id]
  );

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      dismissToast();
    }, duration);

    return () => {
      document.body.style.overflow = 'auto';
      dismissToast();
    };
  }, [dismissToast, dispatch, duration]);

  return (
    <StyledToast
      dismissable={true}
      kind={typeMap[type]}
      label={message}
      show={true}
      onDismiss={dismissToast}
      position={'top-center'}
      isFloating={true}
      data-testid={`toast-${type}`}
    />
  );
};

const ToastList = () => {
  const toasts = useSelector(getToasts.getToasts) as Array<ToastProps>;
  return toasts.length ? <Toast toast={toasts[0]} /> : null;
};

export default ToastList;
