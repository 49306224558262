import { v4 as uuidv4 } from 'uuid';
import { createReducer } from 'store/utils';
import { TOAST_ACTIONS } from './actions';

const initialState = {
  toasts: [],
};

const addToast = (state, action) => {
  const newToast = {
    ...action.payload,
    id: uuidv4(),
  };

  return {
    ...state,
    toasts: [...state.toasts, newToast],
  };
};

const removeToast = (state, action) => {
  return {
    ...state,
    toasts: state.toasts.filter(elem => elem.id !== action.payload),
  };
};

export default createReducer(initialState, {
  [TOAST_ACTIONS.ADD_TOAST]: addToast,
  [TOAST_ACTIONS.REMOVE_TOAST]: removeToast,
});
