import { useContext, useMemo } from 'react';
import { FeatureFlagsContext, unleash } from 'hooks/useFeatureFlags';
import strategyMap from 'hooks/useFeatureFlags/strategies';
import { FeatureFlagsDictionary } from 'hooks/useFeatureFlags/types';
import { safeJsonParse } from 'ds4/utils/safeJsonParse';
import { IVariant } from 'unleash-proxy-client';

export const parseUnleashVariant = ({ variant }: { variant: IVariant }) => {
  if (!variant) {
    return {};
  }

  const { name, payload } = variant;
  const { type, value } = payload || {};
  if (type === 'json') {
    return { name, value: safeJsonParse(value) };
  }
  return { name, value };
};

export const isFeatureFlagActive = (
  flags: FeatureFlagsDictionary,
  flagName: string
) => {
  const flag = flags?.[flagName];
  const isActive = flag?.active ?? false;
  const strategies = flag?.strategies ?? [];
  const mappedStrategies = strategies.map(
    strategy => strategyMap[strategy.name] ?? strategyMap.default
  );
  const isApplicable = mappedStrategies.some((strategy, idx) =>
    strategy(strategies[idx])
  );
  return isApplicable && isActive;
};

const useFeatureFlag = (flagName: string) => {
  const { flags, isFetching } = useContext(FeatureFlagsContext);
  const active = useMemo(
    () => isFeatureFlagActive(flags, flagName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flagName, flags, isFetching]
  );
  const unleashFlagActive = unleash.isEnabled(flagName);

  return useMemo(
    () => ({
      active: active || unleashFlagActive,
      isFetching,
    }),
    [active, isFetching, unleashFlagActive]
  );
};

export default useFeatureFlag;
