export const dummyOrderById = {
  data: {
    order: {
      _id: '5e66cf51596f6c861b8cb882',
      cartId: '5e66cf51f31c47368e97e873',
      customerUserId: 3000000065,
      customerAccountId: '5e4d8b52488c009276460ff6',
      customerEmail: 'Sanford_Huel@yahoo.com',
      orderTotal: 10814.550000000001,
      taxTotal: 1093.51,
      channel: 11,
      status: 'ORDER_SHIPPED',
      statusLog: 'Order has been shipped',
      captureDate: '2019-06-19T07:56:52.435Z',
      items: [
        {
          discountId: ['100001', '100002'],
          _id: '5e66cf51596f6c861b8cb883',
          lineItem: 1,
          itemId: 1000000051,
          sku: '1538910',
          title: 'abcDNA Luminous Cushion Lagoon',
          quantity: 9,
          price: 71.79,
          discount: 1.51,
          currency: 'USD',
          shipToId: 2000000051,
          estimatedTax: 0.4,
          committedTax: 0.4,
          total: 6.9,
          taxCode: 'FR02000',
          imageUrl: 'http://lorempixel.com/640/480',
          id: '5e66cf51596f6c861b8cb883',
        },
        {
          discountId: ['100001', '100002'],
          _id: '5e66cf51596f6c861b8cb884',
          lineItem: 2,
          itemId: 1000000011,
          sku: '1577290',
          title: 'Elli Popp Forever And A Day Pillow Dusk',
          quantity: 14,
          price: 83.52,
          discount: 1.51,
          currency: 'USD',
          shipToId: 2000000051,
          estimatedTax: 0.4,
          committedTax: 0.4,
          total: 6.9,
          taxCode: 'FR02000',
          imageUrl: 'http://lorempixel.com/640/480',
          id: '5e66cf51596f6c861b8cb884',
        },
        {
          discountId: ['100001', '100002'],
          _id: '5e66cf51596f6c861b8cb885',
          lineItem: 3,
          itemId: 1000000056,
          sku: '1539168',
          title: 'Leizu by Elizabeth Few Studio Carnation Pillow Pink',
          quantity: 9,
          price: 810.8,
          discount: 1.51,
          currency: 'USD',
          shipToId: 2000000053,
          estimatedTax: 0.4,
          committedTax: 0.4,
          total: 6.9,
          taxCode: 'FR02000',
          imageUrl: 'http://lorempixel.com/640/480',
          id: '5e66cf51596f6c861b8cb885',
        },
      ],
      shipTo: [
        {
          address: {
            city: 'New Cornellland',
            country: 'Jersey',
            email: 'Sanford_Huel@yahoo.com',
            kind: 'ship to address',
            name: { first: 'Craig', last: 'Frami' },
            phone: { number: '295-025-6000 x5962', kind: 'Mobile' },
            state: 'Maine',
            street1: '251 Mohamed Avenue',
            street2: '',
            zipCode: '07665-1248',
          },
          _id: '5e66cf51596f6c861b8cb886',
          shipToId: 2000000051,
          price: 910.84,
          currency: 'USD',
          estimatedTax: 0.4,
          committedTax: 0.4,
          total: 6.9,
          taxCode: 'FR02000',
          shipmentCarrier: 'Fedex',
          shipmentMethod: 'Express',
          shipmentMethodId: 1234324,
          promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
          shipmentInstructions: 'Leave order at door',
          id: '5e66cf51596f6c861b8cb886',
        },
        {
          address: {
            city: 'West Laishashire',
            country: 'Saint Pierre and Miquelon',
            email: 'Sanford_Huel@yahoo.com',
            kind: 'ship to address',
            name: { first: 'Nya', last: 'McLaughlin' },
            phone: { number: '554.593.1298 x63621', kind: 'Mobile' },
            state: 'Oklahoma',
            street1: '23617 Citlalli Knoll',
            street2: '',
            zipCode: '49404-3374',
          },
          _id: '5e66cf51596f6c861b8cb887',
          shipToId: 2000000053,
          price: 791.12,
          currency: 'USD',
          estimatedTax: 0.4,
          committedTax: 0.4,
          total: 6.9,
          taxCode: 'FR02000',
          shipmentCarrier: 'Fedex',
          shipmentMethod: 'Express',
          shipmentMethodId: 1234324,
          promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
          shipmentInstructions: 'Leave order at door',
          id: '5e66cf51596f6c861b8cb887',
        },
      ],
      orderId: '7790-4752-41002',
      revision: 2,
      totalQuantity: 32,
      shipments: [
        {
          _id: '5e66cf51596f6c861b8cb896',
          shipToId: 2000000051,
          trackingNumber: '2345367890876543',
          shipmentStatus: 'awaiting shipping',
          estimatedDeliveryDate: '2020-11-20T11:19:29.960Z',
          lineItems: [
            {
              _id: '5e66cf51596f6c861b8cb897',
              lineItem: 1,
              quantity: 9,
              id: '5e66cf51596f6c861b8cb897',
            },
            {
              _id: '5e66cf51596f6c861b8cb898',
              lineItem: 2,
              quantity: 12,
              id: '5e66cf51596f6c861b8cb898',
            },
          ],
          id: '5e66cf51596f6c861b8cb896',
        },
        {
          _id: '5e66cf51596f6c861b8cb899',
          shipToId: 2000000053,
          trackingNumber: '2345367890876555',
          shipmentStatus: 'awaiting shipping',
          estimatedDeliveryDate: '2021-03-02T16:07:42.957Z',
          lineItems: [
            {
              _id: '5e66cf51596f6c861b8cb89a',
              lineItem: 3,
              quantity: 6,
              id: '5e66cf51596f6c861b8cb89a',
            },
          ],
          id: '5e66cf51596f6c861b8cb899',
        },
      ],
      payments: [
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Ali Pay',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Amex',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Apple Pay',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Bancontact',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Discover',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Gift Card',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Giro Pay',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Google Pay',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Ideal',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'JCB',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Klarna',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Mastercard',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Payoneer',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'PayPal',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Sepa',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Sofort',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Stripe',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'UnionPay',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Visa',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
        {
          shipToId: [2000000051, 2000000053],
          _id: '5e66cf51596f6c861b8cb88e',
          paymentIdentifier: {
            cardIdentifier: '7589',
          },
          billToId: 7000000055,
          paymentMethod: 'Wechat',
          amount: 15.99,
          currency: 'USD',
          conversion: '1.05',
          paymentDetails: '{}',
          paymentStatus: 'On Hold',
          billToAddress: {
            fullName: 'Enos Streich',
            name: { first: 'Enos', middle: '', last: 'Streich' },
            email: 'Vella_Mraz@yahoo.com',
            phone: { number: '(205) 982-0099 x2195' },
            street1: '07731 Destinee Crest',
            street2: '',
            city: 'North Florence',
            state: 'Indiana',
            country: 'Iran',
            zipCode: '58194',
            kind: 'bill to address',
          },
          id: '5e66cf51596f6c861b8cb88e',
        },
      ],
      createdAt: '2020-03-09T23:20:49.416Z',
      updatedAt: '2020-03-09T23:20:49.479Z',
      __v: 0,
    },
    history: [
      {
        _id: '5e66cf51596f6c861b8cb89b',
        cartId: '5e66cf51f31c47368e97e873',
        customerUserId: 3000000065,
        customerAccountId: '5e4d8b52488c009276460ff6',
        customerEmail: 'Sanford_Huel@yahoo.com',
        orderTotal: 10814.550000000001,
        taxTotal: 1093.51,
        channel: 11,
        status: 'ORDER_SHIPPED',
        statusLog: 'Order has been shipped',
        captureDate: '2019-06-19T07:56:52.435Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e66cf51596f6c861b8cb883',
            lineItem: 1,
            itemId: 1000000051,
            sku: '1538910',
            title: 'abcDNA Luminous Cushion Lagoon',
            quantity: 9,
            price: 71.79,
            discount: 1.51,
            currency: 'USD',
            shipToId: 2000000051,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            imageUrl: 'http://lorempixel.com/640/480',
            id: '5e66cf51596f6c861b8cb883',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e66cf51596f6c861b8cb884',
            lineItem: 2,
            itemId: 1000000011,
            sku: '1577290',
            title: 'Elli Popp Forever And A Day Pillow Dusk',
            quantity: 14,
            price: 83.52,
            discount: 1.51,
            currency: 'USD',
            shipToId: 2000000051,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            imageUrl: 'http://lorempixel.com/640/480',
            id: '5e66cf51596f6c861b8cb884',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e66cf51596f6c861b8cb885',
            lineItem: 3,
            itemId: 1000000056,
            sku: '1539168',
            title: 'Leizu by Elizabeth Few Studio Carnation Pillow Pink',
            quantity: 9,
            price: 810.8,
            discount: 1.51,
            currency: 'USD',
            shipToId: 2000000053,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            imageUrl: 'http://lorempixel.com/640/480',
            id: '5e66cf51596f6c861b8cb885',
          },
        ],
        shipTo: [
          {
            _id: '5e66cf51596f6c861b8cb886',
            shipToId: 2000000051,
            fullName: 'Craig Frami',
            email: 'Sanford_Huel@yahoo.com',
            phone: '295-025-6000 x5962',
            street1: '251 Mohamed Avenue',
            street2: '',
            city: 'New Cornellland',
            state: 'Maine',
            country: 'Jersey',
            zipCode: '07665-1248',
            kind: 'ship to address',
            price: 910.84,
            currency: 'USD',
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1234324,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e66cf51596f6c861b8cb886',
          },
          {
            _id: '5e66cf51596f6c861b8cb887',
            shipToId: 2000000053,
            fullName: 'Nya McLaughlin',
            email: 'Sanford_Huel@yahoo.com',
            phone: '554.593.1298 x63621',
            street1: '23617 Citlalli Knoll',
            street2: '',
            city: 'West Laishashire',
            state: 'Oklahoma',
            country: 'Saint Pierre and Miquelon',
            zipCode: '49404-3374',
            kind: 'ship to address',
            price: 791.12,
            currency: 'USD',
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1234324,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e66cf51596f6c861b8cb887',
          },
        ],
        orderId: '7790-4752-41002',
        revision: 2,
        totalQuantity: 32,
        shipments: [
          {
            _id: '5e66cf51596f6c861b8cb896',
            shipToId: 2000000051,
            trackingNumber: '2345367890876543',
            shipmentStatus: 'awaiting shipping',
            estimatedDeliveryDate: '2020-11-20T11:19:29.960Z',
            lineItems: [
              {
                _id: '5e66cf51596f6c861b8cb897',
                lineItem: 1,
                quantity: 9,
                id: '5e66cf51596f6c861b8cb897',
              },
              {
                _id: '5e66cf51596f6c861b8cb898',
                lineItem: 2,
                quantity: 12,
                id: '5e66cf51596f6c861b8cb898',
              },
            ],
            id: '5e66cf51596f6c861b8cb896',
          },
          {
            _id: '5e66cf51596f6c861b8cb899',
            shipToId: 2000000053,
            trackingNumber: '2345367890876555',
            shipmentStatus: 'awaiting shipping',
            estimatedDeliveryDate: '2021-03-02T16:07:42.957Z',
            lineItems: [
              {
                _id: '5e66cf51596f6c861b8cb89a',
                lineItem: 3,
                quantity: 6,
                id: '5e66cf51596f6c861b8cb89a',
              },
            ],
            id: '5e66cf51596f6c861b8cb899',
          },
        ],
        payments: [
          {
            shipToId: [2000000051, 2000000053],
            _id: '5e66cf51596f6c861b8cb88e',
            paymentIdentifier: '349f2abd-2b0d-4889-9b16-793d2ed31f6d',
            billToId: 7000000055,
            paymentMethod: 'Visa',
            amount: 15.99,
            currency: 'USD',
            conversion: '1.05',
            paymentDetails: '{}',
            paymentStatus: 'On Hold',
            billToAddress: {
              fullName: 'Enos Streich',
              email: 'Vella_Mraz@yahoo.com',
              phone: '(205) 982-0099 x2195',
              street1: '07731 Destinee Crest',
              street2: '',
              city: 'North Florence',
              state: 'Indiana',
              country: 'Iran',
              zipCode: '58194',
              kind: 'bill to address',
            },
            id: '5e66cf51596f6c861b8cb88e',
          },
        ],
        __v: 0,
        createdAt: '2020-03-09T23:20:49.489Z',
        updatedAt: '2020-03-09T23:20:49.489Z',
      },
      {
        _id: '5e66cf51596f6c861b8cb88f',
        cartId: '5e66cf51f31c47368e97e873',
        customerUserId: 3000000065,
        customerAccountId: '5e4d8b52488c009276460ff6',
        customerEmail: 'Sanford_Huel@yahoo.com',
        orderTotal: 10814.550000000001,
        taxTotal: 1093.51,
        channel: 11,
        status: 'ORDER_PAYMENT_AUTHORIZED',
        statusLog: 'Payment has been otherized',
        captureDate: '2019-06-19T07:56:52.435Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e66cf51596f6c861b8cb883',
            lineItem: 1,
            itemId: 1000000051,
            sku: '1538910',
            title: 'abcDNA Luminous Cushion Lagoon',
            quantity: 9,
            price: 71.79,
            discount: 1.51,
            currency: 'USD',
            shipToId: 2000000051,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            imageUrl: 'http://lorempixel.com/640/480',
            id: '5e66cf51596f6c861b8cb883',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e66cf51596f6c861b8cb884',
            lineItem: 2,
            itemId: 1000000011,
            sku: '1577290',
            title: 'Elli Popp Forever And A Day Pillow Dusk',
            quantity: 14,
            price: 83.52,
            discount: 1.51,
            currency: 'USD',
            shipToId: 2000000051,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            imageUrl: 'http://lorempixel.com/640/480',
            id: '5e66cf51596f6c861b8cb884',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e66cf51596f6c861b8cb885',
            lineItem: 3,
            itemId: 1000000056,
            sku: '1539168',
            title: 'Leizu by Elizabeth Few Studio Carnation Pillow Pink',
            quantity: 9,
            price: 810.8,
            discount: 1.51,
            currency: 'USD',
            shipToId: 2000000053,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            imageUrl: 'http://lorempixel.com/640/480',
            id: '5e66cf51596f6c861b8cb885',
          },
        ],
        shipTo: [
          {
            _id: '5e66cf51596f6c861b8cb886',
            shipToId: 2000000051,
            fullName: 'Craig Frami',
            email: 'Sanford_Huel@yahoo.com',
            phone: '295-025-6000 x5962',
            street1: '251 Mohamed Avenue',
            street2: '',
            city: 'New Cornellland',
            state: 'Maine',
            country: 'Jersey',
            zipCode: '07665-1248',
            kind: 'ship to address',
            price: 910.84,
            currency: 'USD',
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1234324,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e66cf51596f6c861b8cb886',
          },
          {
            _id: '5e66cf51596f6c861b8cb887',
            shipToId: 2000000053,
            fullName: 'Nya McLaughlin',
            email: 'Sanford_Huel@yahoo.com',
            phone: '554.593.1298 x63621',
            street1: '23617 Citlalli Knoll',
            street2: '',
            city: 'West Laishashire',
            state: 'Oklahoma',
            country: 'Saint Pierre and Miquelon',
            zipCode: '49404-3374',
            kind: 'ship to address',
            price: 791.12,
            currency: 'USD',
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1234324,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e66cf51596f6c861b8cb887',
          },
        ],
        orderId: '7790-4752-41002',
        revision: 1,
        totalQuantity: 32,
        shipments: [],
        payments: [
          {
            shipToId: [2000000051, 2000000053],
            _id: '5e66cf51596f6c861b8cb88e',
            paymentIdentifier: '349f2abd-2b0d-4889-9b16-793d2ed31f6d',
            billToId: 7000000055,
            paymentMethod: 'Visa',
            amount: 15.99,
            currency: 'USD',
            conversion: '1.05',
            paymentDetails: '{}',
            paymentStatus: 'On Hold',
            billToAddress: {
              fullName: 'Enos Streich',
              email: 'Vella_Mraz@yahoo.com',
              phone: '(205) 982-0099 x2195',
              street1: '07731 Destinee Crest',
              street2: '',
              city: 'North Florence',
              state: 'Indiana',
              country: 'Iran',
              zipCode: '58194',
              kind: 'bill to address',
            },
            id: '5e66cf51596f6c861b8cb88e',
          },
        ],
        __v: 0,
        createdAt: '2020-03-09T23:20:49.457Z',
        updatedAt: '2020-03-09T23:20:49.457Z',
      },
      {
        _id: '5e66cf51596f6c861b8cb888',
        cartId: '5e66cf51f31c47368e97e873',
        customerUserId: 3000000065,
        customerAccountId: '5e4d8b52488c009276460ff6',
        customerEmail: 'Sanford_Huel@yahoo.com',
        orderTotal: 10814.550000000001,
        taxTotal: 1093.51,
        channel: 11,
        status: 'ORDER_CREATED',
        statusLog: 'Order has been created',
        captureDate: '2019-06-19T07:56:52.435Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e66cf51596f6c861b8cb883',
            lineItem: 1,
            itemId: 1000000051,
            sku: '1538910',
            title: 'abcDNA Luminous Cushion Lagoon',
            quantity: 9,
            price: 71.79,
            discount: 1.51,
            currency: 'USD',
            shipToId: 2000000051,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            imageUrl: 'http://lorempixel.com/640/480',
            id: '5e66cf51596f6c861b8cb883',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e66cf51596f6c861b8cb884',
            lineItem: 2,
            itemId: 1000000011,
            sku: '1577290',
            title: 'Elli Popp Forever And A Day Pillow Dusk',
            quantity: 14,
            price: 83.52,
            discount: 1.51,
            currency: 'USD',
            shipToId: 2000000051,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            imageUrl: 'http://lorempixel.com/640/480',
            id: '5e66cf51596f6c861b8cb884',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e66cf51596f6c861b8cb885',
            lineItem: 3,
            itemId: 1000000056,
            sku: '1539168',
            title: 'Leizu by Elizabeth Few Studio Carnation Pillow Pink',
            quantity: 9,
            price: 810.8,
            discount: 1.51,
            currency: 'USD',
            shipToId: 2000000053,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            imageUrl: 'http://lorempixel.com/640/480',
            id: '5e66cf51596f6c861b8cb885',
          },
        ],
        shipTo: [
          {
            _id: '5e66cf51596f6c861b8cb886',
            shipToId: 2000000051,
            fullName: 'Craig Frami',
            email: 'Sanford_Huel@yahoo.com',
            phone: '295-025-6000 x5962',
            street1: '251 Mohamed Avenue',
            street2: '',
            city: 'New Cornellland',
            state: 'Maine',
            country: 'Jersey',
            zipCode: '07665-1248',
            kind: 'ship to address',
            price: 910.84,
            currency: 'USD',
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1234324,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e66cf51596f6c861b8cb886',
          },
          {
            _id: '5e66cf51596f6c861b8cb887',
            shipToId: 2000000053,
            fullName: 'Nya McLaughlin',
            email: 'Sanford_Huel@yahoo.com',
            phone: '554.593.1298 x63621',
            street1: '23617 Citlalli Knoll',
            street2: '',
            city: 'West Laishashire',
            state: 'Oklahoma',
            country: 'Saint Pierre and Miquelon',
            zipCode: '49404-3374',
            kind: 'ship to address',
            price: 791.12,
            currency: 'USD',
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1234324,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e66cf51596f6c861b8cb887',
          },
        ],
        orderId: '7790-4752-41002',
        revision: 0,
        totalQuantity: 32,
        shipments: [],
        payments: [],
        createdAt: '2020-03-09T23:20:49.420Z',
        updatedAt: '2020-03-09T23:20:49.420Z',
        __v: 0,
      },
    ],
    items: [
      {
        _id: '5e3598e31762980008a9cc8d',
        itemId: 1000000011,
        __v: 0,
        attributes: [
          {
            isDefault: false,
            _id: '5e37218804d448000809a9be',
            name: 'Short Description',
            value:
              'Exclusively at ABC, these decorative pillows offer atmospheric, romantic designs in cool tones, creating a balance of color, shape, and fluidity. Inspired by sacred expressions of the natural world and made from silk, these pillows add depth & elegance to any interior.',
            id: '5e37218804d448000809a9be',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9bd',
            name: 'UPC',
            value: '',
            id: '5e37218804d448000809a9bd',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9bc',
            name: 'Brand',
            value: 'elli popp',
            id: '5e37218804d448000809a9bc',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9bb',
            name: 'Color',
            value: 'DUSK',
            id: '5e37218804d448000809a9bb',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9ba',
            name: 'Length',
            value: '12 l',
            id: '5e37218804d448000809a9ba',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9b9',
            name: 'Width',
            value: '20 w',
            id: '5e37218804d448000809a9b9',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9b8',
            name: 'Height',
            value: '',
            id: '5e37218804d448000809a9b8',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9b7',
            name: 'Depth',
            value: '',
            id: '5e37218804d448000809a9b7',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9b6',
            name: 'Alternate Sizing',
            value: '',
            id: '5e37218804d448000809a9b6',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9b5',
            name: 'Style',
            value: '',
            id: '5e37218804d448000809a9b5',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9b4',
            name: 'Material',
            value: '100% Silk',
            id: '5e37218804d448000809a9b4',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9b3',
            name: 'Weight',
            value: '3 lb',
            id: '5e37218804d448000809a9b3',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9b2',
            name: 'Care',
            value: 'Dry clean only',
            id: '5e37218804d448000809a9b2',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9b1',
            name: 'Exclusively at ABC',
            value: true,
            id: '5e37218804d448000809a9b1',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9b0',
            name: 'Community/Coop',
            value: false,
            id: '5e37218804d448000809a9b0',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9af',
            name: 'Energy Conscious',
            value: false,
            id: '5e37218804d448000809a9af',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9ae',
            name: 'Goodcolor',
            value: false,
            id: '5e37218804d448000809a9ae',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9ad',
            name: 'Goodwood',
            value: false,
            id: '5e37218804d448000809a9ad',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9ac',
            name: 'Indigenous',
            value: false,
            id: '5e37218804d448000809a9ac',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9ab',
            name: 'Local Economy',
            value: false,
            id: '5e37218804d448000809a9ab',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9aa',
            name: 'Pure',
            value: false,
            id: '5e37218804d448000809a9aa',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9a9',
            name: 'Certified',
            value: false,
            id: '5e37218804d448000809a9a9',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9a8',
            name: 'Cruelty Free',
            value: false,
            id: '5e37218804d448000809a9a8',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9a7',
            name: 'Fair & Square',
            value: false,
            id: '5e37218804d448000809a9a7',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9a6',
            name: 'Goodthread',
            value: false,
            id: '5e37218804d448000809a9a6',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9a5',
            name: 'Handmade',
            value: false,
            id: '5e37218804d448000809a9a5',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9a4',
            name: 'Life cycle',
            value: false,
            id: '5e37218804d448000809a9a4',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9a3',
            name: 'Organic',
            value: false,
            id: '5e37218804d448000809a9a3',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9a2',
            name: 'Recycled',
            value: false,
            id: '5e37218804d448000809a9a2',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9a1',
            name: 'ABC Exclusive',
            value: '',
            id: '5e37218804d448000809a9a1',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a9a0',
            name: 'Final Sale',
            value: false,
            id: '5e37218804d448000809a9a0',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a99f',
            name: "Buyer's Pick",
            value: '',
            id: '5e37218804d448000809a99f',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a99e',
            name: 'Tax Code',
            value: 'PH060771',
            id: '5e37218804d448000809a99e',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a99d',
            name: 'Restrict Shipment',
            value: '',
            id: '5e37218804d448000809a99d',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a99c',
            name: 'Shipping Type',
            value: 'Parcel',
            id: '5e37218804d448000809a99c',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a99b',
            name: 'Dropship',
            value: false,
            id: '5e37218804d448000809a99b',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a99a',
            name: 'Product Type (LT)',
            value: 'Std Lead Time',
            id: '5e37218804d448000809a99a',
          },
          {
            isDefault: false,
            _id: '5e37218804d448000809a999',
            name: 'Lead Time',
            value: '',
            id: '5e37218804d448000809a999',
          },
        ],
        channel: [12, 13],
        createdAt: '2020-02-02T18:13:29.264Z',
        description:
          "&bull; 12'l x 20'w<br />&bull; Double sided silk print; cotton lining<br />&bull; Hidden zipper closure<br />&bull; Made in England",
        endDate: '2099-01-28T12:10:14.000Z',
        files: [],
        group: [
          {
            _id: '5e2f45bb0aefb70008e96c42',
            channel: [],
            isInternal: false,
            isActive: true,
            isSoftDeleted: false,
            name: 'Patterns',
            kind: 'CATEGORY',
            parent: '5e2cfab345570b000863c4b0',
            groupId: 153,
            images: [],
            videos: [],
            createdAt: '2020-01-27T20:19:07.906Z',
            updatedAt: '2020-01-27T20:19:07.906Z',
            __v: 0,
          },
        ],
        images: [
          {
            _id: '5e35991b007c5e00080d34f8',
            order: 0,
            label: 'Elli Popp Forever And A Day Pillow Dusk',
            source: [
              {
                _id: '5e35991b007c5e00080d34f9',
                url: 'https://www.abchome.com/shop/media/catalog/product/1/5/1577290-forever-and-a-day-pillow-dusk-a.jpg',
                id: '5e35991b007c5e00080d34f9',
              },
            ],
            id: '5e35991b007c5e00080d34f8',
          },
        ],
        imagesSetOnAttributes: [],
        isActive: true,
        isDefault: false,
        isSoftDeleted: false,
        itemFamily: '5e3558e31fd6e900078ffaa8',
        sku: '1577290',
        startDate: '2020-01-30T07:30:00.000Z',
        state: 'READY',
        title: 'Elli Popp Forever And A Day Pillow Dusk',
        updatedAt: '2020-02-02T19:22:48.970Z',
        variants: [],
        videos: [],
      },
      {
        _id: '5e3598e3007c5e00080d2bb8',
        itemId: 1000000051,
        __v: 0,
        attributes: [
          {
            isDefault: false,
            _id: '5e371e5904d4480008094163',
            name: 'Short Description',
            value:
              'Exclusively at ABC, the abcDNA luminous collection is mindfully handmade by local artisans in India. Plush cushions and mats are rendered in rich velvet, carefully hand-finished with a cross-stitch design. Made for meditation spaces in hues ranging from petal to deep charcoal, its surface provides a comfortable, warm space - the ideal refuge for reflection and spiritual connection.',
            id: '5e371e5904d4480008094163',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094162',
            name: 'UPC',
            value: '',
            id: '5e371e5904d4480008094162',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094161',
            name: 'Brand',
            value: 'abcdna',
            id: '5e371e5904d4480008094161',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094160',
            name: 'Color',
            value: 'LAGOON',
            id: '5e371e5904d4480008094160',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809415f',
            name: 'Length',
            value: '24 l',
            id: '5e371e5904d448000809415f',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809415e',
            name: 'Width',
            value: '24 w',
            id: '5e371e5904d448000809415e',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809415d',
            name: 'Height',
            value: '',
            id: '5e371e5904d448000809415d',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809415c',
            name: 'Depth',
            value: '',
            id: '5e371e5904d448000809415c',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809415b',
            name: 'Alternate Sizing',
            value: '',
            id: '5e371e5904d448000809415b',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809415a',
            name: 'Style',
            value: '',
            id: '5e371e5904d448000809415a',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094159',
            name: 'Material',
            value: '100% Silk Velvet',
            id: '5e371e5904d4480008094159',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094158',
            name: 'Weight',
            value: '1 lb',
            id: '5e371e5904d4480008094158',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094157',
            name: 'Care',
            value: 'Dry clean only',
            id: '5e371e5904d4480008094157',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094156',
            name: 'Exclusively at ABC',
            value: true,
            id: '5e371e5904d4480008094156',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094155',
            name: 'Community/Coop',
            value: true,
            id: '5e371e5904d4480008094155',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094154',
            name: 'Energy Conscious',
            value: false,
            id: '5e371e5904d4480008094154',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094153',
            name: 'Goodcolor',
            value: false,
            id: '5e371e5904d4480008094153',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094152',
            name: 'Goodwood',
            value: false,
            id: '5e371e5904d4480008094152',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094151',
            name: 'Indigenous',
            value: true,
            id: '5e371e5904d4480008094151',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094150',
            name: 'Local Economy',
            value: false,
            id: '5e371e5904d4480008094150',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809414f',
            name: 'Pure',
            value: false,
            id: '5e371e5904d448000809414f',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809414e',
            name: 'Certified',
            value: false,
            id: '5e371e5904d448000809414e',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809414d',
            name: 'Cruelty Free',
            value: false,
            id: '5e371e5904d448000809414d',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809414c',
            name: 'Fair & Square',
            value: false,
            id: '5e371e5904d448000809414c',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809414b',
            name: 'Goodthread',
            value: false,
            id: '5e371e5904d448000809414b',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809414a',
            name: 'Handmade',
            value: true,
            id: '5e371e5904d448000809414a',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094149',
            name: 'Life cycle',
            value: false,
            id: '5e371e5904d4480008094149',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094148',
            name: 'Organic',
            value: false,
            id: '5e371e5904d4480008094148',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094147',
            name: 'Recycled',
            value: false,
            id: '5e371e5904d4480008094147',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094146',
            name: 'ABC Exclusive',
            value: '',
            id: '5e371e5904d4480008094146',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094145',
            name: 'Final Sale',
            value: false,
            id: '5e371e5904d4480008094145',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094144',
            name: "Buyer's Pick",
            value: '',
            id: '5e371e5904d4480008094144',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094143',
            name: 'Tax Code',
            value: 'PH060771',
            id: '5e371e5904d4480008094143',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094142',
            name: 'Restrict Shipment',
            value: '',
            id: '5e371e5904d4480008094142',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094141',
            name: 'Shipping Type',
            value: 'Parcel',
            id: '5e371e5904d4480008094141',
          },
          {
            isDefault: false,
            _id: '5e371e5904d4480008094140',
            name: 'Dropship',
            value: false,
            id: '5e371e5904d4480008094140',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809413f',
            name: 'Product Type (LT)',
            value: 'Std Lead Time',
            id: '5e371e5904d448000809413f',
          },
          {
            isDefault: false,
            _id: '5e371e5904d448000809413e',
            name: 'Lead Time',
            value: '',
            id: '5e371e5904d448000809413e',
          },
        ],
        channel: [12, 13],
        createdAt: '2020-02-02T18:24:17.686Z',
        description:
          '&bull; 24”l x 24”w<br />&bull; Silk velvet; cross-stitch; cotton fill<br />&bull; Handmade in India',
        endDate: '2099-01-28T12:10:14.000Z',
        files: [],
        group: [
          {
            _id: '5e2cfa9e45570b000863c4ae',
            channel: [],
            isInternal: false,
            isActive: true,
            isSoftDeleted: false,
            name: 'Cushions',
            kind: 'CATEGORY',
            parent: '5e2cf42d45570b000863c45a',
            groupId: 99,
            images: [],
            videos: [],
            createdAt: '2020-01-26T02:34:06.940Z',
            updatedAt: '2020-01-26T02:34:06.940Z',
            __v: 0,
          },
        ],
        images: [
          {
            _id: '5e35991b741f500008849a76',
            order: 0,
            label: 'abcDNA Luminous Cushion Lagoon',
            source: [
              {
                _id: '5e35991b741f500008849a77',
                url: 'https://www.abchome.com/shop/media/catalog/product/1/5/1538910-abcdna-luminous-cushion-lagoon-a.jpg',
                id: '5e35991b741f500008849a77',
              },
            ],
            id: '5e35991b741f500008849a76',
          },
        ],
        imagesSetOnAttributes: [],
        isActive: true,
        isDefault: false,
        isSoftDeleted: false,
        itemFamily: '5e3558e31fd6e900078ffaa8',
        sku: '1538910',
        startDate: '2020-01-30T07:30:00.000Z',
        state: 'READY',
        title: 'abcDNA Luminous Cushion Lagoon',
        updatedAt: '2020-02-02T19:09:13.600Z',
        variants: [],
        videos: [],
      },
      {
        _id: '5e3598e31895e00007710b06',
        itemId: 1000000056,
        __v: 0,
        attributes: [
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839321',
            name: 'Short Description',
            value:
              'Inspired by the legend of Empress Leizu of China, this one-of-a-kind hand-dyed botanical silk pillow is a nod to ancient wisdom and the beauty of nature. Crafted in small bundles with only natural dyes and botanicals, over time the colors and prints may fade while maintaining a rich and beautiful appearance. Made in Richmond, VA.',
            id: '5e3720ecd28b930007839321',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839320',
            name: 'UPC',
            value: '',
            id: '5e3720ecd28b930007839320',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783931f',
            name: 'Brand',
            value: 'studio natural',
            id: '5e3720ecd28b93000783931f',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783931e',
            name: 'Color',
            value: 'PINK',
            id: '5e3720ecd28b93000783931e',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783931d',
            name: 'Length',
            value: '22 l',
            id: '5e3720ecd28b93000783931d',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783931c',
            name: 'Width',
            value: '22 w',
            id: '5e3720ecd28b93000783931c',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783931b',
            name: 'Height',
            value: '',
            id: '5e3720ecd28b93000783931b',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783931a',
            name: 'Depth',
            value: '',
            id: '5e3720ecd28b93000783931a',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839319',
            name: 'Alternate Sizing',
            value: '',
            id: '5e3720ecd28b930007839319',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839318',
            name: 'Style',
            value: '',
            id: '5e3720ecd28b930007839318',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839317',
            name: 'Material',
            value: '100% Silk',
            id: '5e3720ecd28b930007839317',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839316',
            name: 'Weight',
            value: '1 lb',
            id: '5e3720ecd28b930007839316',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839315',
            name: 'Care',
            value: 'Dry clean only',
            id: '5e3720ecd28b930007839315',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839314',
            name: 'Exclusively at ABC',
            value: true,
            id: '5e3720ecd28b930007839314',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839313',
            name: 'Community/Coop',
            value: false,
            id: '5e3720ecd28b930007839313',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839312',
            name: 'Energy Conscious',
            value: false,
            id: '5e3720ecd28b930007839312',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839311',
            name: 'Goodcolor',
            value: true,
            id: '5e3720ecd28b930007839311',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839310',
            name: 'Goodwood',
            value: false,
            id: '5e3720ecd28b930007839310',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783930f',
            name: 'Indigenous',
            value: false,
            id: '5e3720ecd28b93000783930f',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783930e',
            name: 'Local Economy',
            value: false,
            id: '5e3720ecd28b93000783930e',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783930d',
            name: 'Pure',
            value: false,
            id: '5e3720ecd28b93000783930d',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783930c',
            name: 'Certified',
            value: false,
            id: '5e3720ecd28b93000783930c',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783930b',
            name: 'Cruelty Free',
            value: false,
            id: '5e3720ecd28b93000783930b',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b93000783930a',
            name: 'Fair & Square',
            value: false,
            id: '5e3720ecd28b93000783930a',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839309',
            name: 'Goodthread',
            value: false,
            id: '5e3720ecd28b930007839309',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839308',
            name: 'Handmade',
            value: true,
            id: '5e3720ecd28b930007839308',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839307',
            name: 'Life cycle',
            value: false,
            id: '5e3720ecd28b930007839307',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839306',
            name: 'Organic',
            value: false,
            id: '5e3720ecd28b930007839306',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839305',
            name: 'Recycled',
            value: false,
            id: '5e3720ecd28b930007839305',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839304',
            name: 'ABC Exclusive',
            value: '',
            id: '5e3720ecd28b930007839304',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839303',
            name: 'Final Sale',
            value: false,
            id: '5e3720ecd28b930007839303',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839302',
            name: "Buyer's Pick",
            value: '',
            id: '5e3720ecd28b930007839302',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839301',
            name: 'Tax Code',
            value: 'PH060771',
            id: '5e3720ecd28b930007839301',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b930007839300',
            name: 'Restrict Shipment',
            value: '',
            id: '5e3720ecd28b930007839300',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b9300078392ff',
            name: 'Shipping Type',
            value: 'Parcel',
            id: '5e3720ecd28b9300078392ff',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b9300078392fe',
            name: 'Dropship',
            value: false,
            id: '5e3720ecd28b9300078392fe',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b9300078392fd',
            name: 'Product Type (LT)',
            value: 'Std Lead Time',
            id: '5e3720ecd28b9300078392fd',
          },
          {
            isDefault: false,
            _id: '5e3720ecd28b9300078392fc',
            name: 'Lead Time',
            value: '',
            id: '5e3720ecd28b9300078392fc',
          },
        ],
        channel: [12, 13],
        createdAt: '2020-02-02T18:30:05.122Z',
        description:
          "&bull; 22'l x 22'w<br />&bull; Silk<br />&bull; Made in the USA",
        endDate: '2099-01-28T12:10:14.000Z',
        files: [],
        group: [
          {
            _id: '5e2f45bb0aefb70008e96c42',
            channel: [],
            isInternal: false,
            isActive: true,
            isSoftDeleted: false,
            name: 'Patterns',
            kind: 'CATEGORY',
            parent: '5e2cfab345570b000863c4b0',
            groupId: 153,
            images: [],
            videos: [],
            createdAt: '2020-01-27T20:19:07.906Z',
            updatedAt: '2020-01-27T20:19:07.906Z',
            __v: 0,
          },
        ],
        images: [
          {
            _id: '5e35991ccf5963000767f428',
            order: 0,
            label: 'Leizu by Elizabeth Few Studio Carnation Pillow Pink',
            source: [
              {
                _id: '5e35991ccf5963000767f429',
                url: 'https://www.abchome.com/shop/media/catalog/product/1/5/1539168-elizabeth-few-leizu-carnation-pillow-pink-a.jpg',
                id: '5e35991ccf5963000767f429',
              },
            ],
            id: '5e35991ccf5963000767f428',
          },
        ],
        imagesSetOnAttributes: [],
        isActive: true,
        isDefault: false,
        isSoftDeleted: false,
        itemFamily: '5e3558e31fd6e900078ffaa8',
        sku: '1539168',
        startDate: '2020-01-30T07:30:00.000Z',
        state: 'READY',
        title: 'Leizu by Elizabeth Few Studio Carnation Pillow Pink',
        updatedAt: '2020-02-02T19:20:12.753Z',
        variants: [],
        videos: [],
      },
    ],
  },
};

export const dummyOrdersList = {
  data: {
    query: {
      limit: 10,
      offset: 0,
      count: 31461,
      orderTotalSum: 15741581.64,
      totalQuantitySum: 817499,
    },
    orders: [
      {
        _id: '5e56f17eb90ed837e89ec81e',
        customerUserId: 1000001,
        customerAccountId: '19a44deb-baf3-4e44-94f1-201d8e2565c4',
        orderTotal: 15.99,
        channel: 12,
        status: 'ORDER_CREATED',
        statusLog: 'Order has been placed',
        captureDate: '2020-02-21T00:00:00.000Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e56f17eb90ed837e89ec81f',
            lineItem: 1,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 25,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR020000',
            id: '5e56f17eb90ed837e89ec81f',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e56f17eb90ed837e89ec820',
            lineItem: 2,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 3,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR020000',
            id: '5e56f17eb90ed837e89ec820',
          },
        ],
        shipTo: [
          {
            _id: '5e56f17eb90ed837e89ec821',
            shipToId: 100001,
            fullName: 'John Smith',
            email: 'johnsmith@gma',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Vancouver',
            state: 'BC',
            country: 'Canada',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56f17eb90ed837e89ec821',
          },
        ],
        shipments: [
          {
            _id: '5e56f17eb90ed837e89ec822',
            shipToId: 100001,
            trackingNumber: '2345367890876543',
            taxCode: 'FR020000',
            lineItems: [
              {
                _id: '5e56f17eb90ed837e89ec823',
                lineItem: 1,
                quantity: 2,
                id: '5e56f17eb90ed837e89ec823',
              },
              {
                _id: '5e56f17eb90ed837e89ec824',
                lineItem: 2,
                quantity: 1,
                id: '5e56f17eb90ed837e89ec824',
              },
            ],
            shipmentStatus: 'awaiting shipping',
            estimatedDeliveryDate: '2019-03-01T00:00:00.000Z',
            id: '5e56f17eb90ed837e89ec822',
          },
        ],
        payments: [
          {
            shipToId: [10001, 1100002],
            _id: '5e56f17eb90ed837e89ec825',
            paymentIdentifier: '349f2abd-2b0d-4889-9b16-793d2ed31f6d',
            paymentMethod: 'Visa',
            amount: 15.99,
            currency: 'USD',
            conversion: '1.05',
            paymentDetails: '{}',
            paymentStatus: 'On Hold',
            id: '5e56f17eb90ed837e89ec825',
          },
        ],
        orderId: '1062-5904-36815',
        totalQuantity: 28,
        createdAt: '2020-02-26T22:30:22.721Z',
        updatedAt: '2020-02-26T22:30:22.721Z',
        __v: 0,
      },
      {
        _id: '5e56f179b90ed837e89ec80e',
        customerUserId: 1000001,
        customerAccountId: '19a44deb-baf3-4e44-94f1-201d8e2565c4',
        orderTotal: 15.99,
        channel: 12,
        status: 'ORDER_CREATED',
        statusLog: 'Order has been placed',
        captureDate: '2020-02-21T00:00:00.000Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e56f179b90ed837e89ec80f',
            lineItem: 1,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 25,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR020000',
            id: '5e56f179b90ed837e89ec80f',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e56f179b90ed837e89ec810',
            lineItem: 2,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 3,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR020000',
            id: '5e56f179b90ed837e89ec810',
          },
        ],
        shipTo: [
          {
            _id: '5e56f179b90ed837e89ec811',
            shipToId: 100001,
            fullName: 'John Smith',
            email: 'johnsmith@gmail',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Vancouver',
            state: 'BC',
            country: 'Canada',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56f179b90ed837e89ec811',
          },
        ],
        shipments: [
          {
            _id: '5e56f179b90ed837e89ec812',
            shipToId: 100001,
            trackingNumber: '2345367890876543',
            taxCode: 'FR020000',
            lineItems: [
              {
                _id: '5e56f179b90ed837e89ec813',
                lineItem: 1,
                quantity: 2,
                id: '5e56f179b90ed837e89ec813',
              },
              {
                _id: '5e56f179b90ed837e89ec814',
                lineItem: 2,
                quantity: 1,
                id: '5e56f179b90ed837e89ec814',
              },
            ],
            shipmentStatus: 'awaiting shipping',
            estimatedDeliveryDate: '2019-03-01T00:00:00.000Z',
            id: '5e56f179b90ed837e89ec812',
          },
        ],
        payments: [
          {
            shipToId: [10001, 1100002],
            _id: '5e56f179b90ed837e89ec815',
            paymentIdentifier: '349f2abd-2b0d-4889-9b16-793d2ed31f6d',
            paymentMethod: 'Visa',
            amount: 15.99,
            currency: 'USD',
            conversion: '1.05',
            paymentDetails: '{}',
            paymentStatus: 'On Hold',
            id: '5e56f179b90ed837e89ec815',
          },
        ],
        orderId: '3798-7471-11899',
        totalQuantity: 28,
        createdAt: '2020-02-26T22:30:17.771Z',
        updatedAt: '2020-02-26T22:30:17.771Z',
        __v: 0,
      },
      {
        _id: '5e56f175b90ed837e89ec7fe',
        customerUserId: 1000001,
        customerAccountId: '19a44deb-baf3-4e44-94f1-201d8e2565c4',
        orderTotal: 15.99,
        channel: 12,
        status: 'ORDER_CREATED',
        statusLog: 'Order has been placed',
        captureDate: '2020-02-21T00:00:00.000Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e56f175b90ed837e89ec7ff',
            lineItem: 1,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 25,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR020000',
            id: '5e56f175b90ed837e89ec7ff',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e56f175b90ed837e89ec800',
            lineItem: 2,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 3,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR020000',
            id: '5e56f175b90ed837e89ec800',
          },
        ],
        shipTo: [
          {
            _id: '5e56f175b90ed837e89ec801',
            shipToId: 100001,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.io',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Vancouver',
            state: 'BC',
            country: 'Canada',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56f175b90ed837e89ec801',
          },
        ],
        shipments: [
          {
            _id: '5e56f175b90ed837e89ec802',
            shipToId: 100001,
            trackingNumber: '2345367890876543',
            taxCode: 'FR020000',
            lineItems: [
              {
                _id: '5e56f175b90ed837e89ec803',
                lineItem: 1,
                quantity: 2,
                id: '5e56f175b90ed837e89ec803',
              },
              {
                _id: '5e56f175b90ed837e89ec804',
                lineItem: 2,
                quantity: 1,
                id: '5e56f175b90ed837e89ec804',
              },
            ],
            shipmentStatus: 'awaiting shipping',
            estimatedDeliveryDate: '2019-03-01T00:00:00.000Z',
            id: '5e56f175b90ed837e89ec802',
          },
        ],
        payments: [
          {
            shipToId: [10001, 1100002],
            _id: '5e56f175b90ed837e89ec805',
            paymentIdentifier: '349f2abd-2b0d-4889-9b16-793d2ed31f6d',
            paymentMethod: 'Visa',
            amount: 15.99,
            currency: 'USD',
            conversion: '1.05',
            paymentDetails: '{}',
            paymentStatus: 'On Hold',
            id: '5e56f175b90ed837e89ec805',
          },
        ],
        orderId: '9486-9462-94446',
        totalQuantity: 28,
        createdAt: '2020-02-26T22:30:13.238Z',
        updatedAt: '2020-02-26T22:30:13.238Z',
        __v: 0,
      },
      {
        _id: '5e56abb9a207857e39ee7bbc',
        customerUserId: 1004001,
        customerAccountId: '19a44deb-baf3-4e44-94f1-201d8e2565c4',
        orderTotal: 15.99,
        channel: 12,
        status: 'ORDER_CANCELLED',
        statusLog: 'Cancelled',
        captureDate: '2020-02-21T00:00:00.000Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e56abb9a207857e39ee7bbd',
            lineItem: 1,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 25,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            id: '5e56abb9a207857e39ee7bbd',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e56abb9a207857e39ee7bbe',
            lineItem: 2,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 3,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            id: '5e56abb9a207857e39ee7bbe',
          },
        ],
        shipTo: [
          {
            _id: '5e56abb9a207857e39ee7bbf',
            shipToId: 100001,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.com',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Vancouver',
            state: 'BC',
            country: 'Canada',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56abb9a207857e39ee7bbf',
          },
          {
            _id: '5e56abb9a207857e39ee7bc0',
            shipToId: 100002,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.com',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Settle',
            state: 'WA',
            country: 'USA',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56abb9a207857e39ee7bc0',
          },
        ],
        orderId: '9601-3683-25044',
        totalQuantity: 28,
        shipments: [
          {
            _id: '5e56ae6b9c86927fb511b749',
            shipToId: 100001,
            trackingNumber: '2345367890876543',
            lineItems: [
              {
                _id: '5e56ae6b9c86927fb511b74a',
                lineItem: 1,
                quantity: 2,
                id: '5e56ae6b9c86927fb511b74a',
              },
              {
                _id: '5e56ae6b9c86927fb511b74b',
                lineItem: 2,
                quantity: 1,
                id: '5e56ae6b9c86927fb511b74b',
              },
            ],
            shipmentStatus: 'awaiting shipping',
            estimatedDeliveryDate: '2019-03-01T00:00:00.000Z',
            taxCode: 'FR030000',
            id: '5e56ae6b9c86927fb511b749',
          },
        ],
        payments: [
          {
            shipToId: [10001, 1100002],
            _id: '5e56ae6b9c86927fb511b748',
            paymentIdentifier: '349f2abd-2b0d-4889-9b16-793d2ed31f6d',
            paymentMethod: 'Visa',
            amount: 15.99,
            currency: 'USD',
            conversion: '1.05',
            paymentDetails: '{}',
            paymentStatus: 'On Hold',
            id: '5e56ae6b9c86927fb511b748',
          },
        ],
        createdAt: '2020-02-26T17:32:41.172Z',
        updatedAt: '2020-02-26T17:44:11.704Z',
        __v: 0,
        revision: 3,
      },
      {
        _id: '5e56ab9fa207857e39ee7bb2',
        customerUserId: 1004001,
        customerAccountId: '19a44deb-baf3-4e44-94f1-201d8e2565c4',
        orderTotal: 15.99,
        channel: 12,
        status: 'ORDER_CREATED',
        statusLog: 'Order has been placed',
        captureDate: '2020-02-21T00:00:00.000Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e56ab9fa207857e39ee7bb3',
            lineItem: 1,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 25,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            id: '5e56ab9fa207857e39ee7bb3',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e56ab9fa207857e39ee7bb4',
            lineItem: 2,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 3,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            taxCode: 'FR02000',
            id: '5e56ab9fa207857e39ee7bb4',
          },
        ],
        shipTo: [
          {
            _id: '5e56ab9fa207857e39ee7bb5',
            shipToId: 100001,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.com',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Vancouver',
            state: 'BC',
            country: 'Canada',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56ab9fa207857e39ee7bb5',
          },
          {
            _id: '5e56ab9fa207857e39ee7bb6',
            shipToId: 100002,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.com',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Settle',
            state: 'WA',
            country: 'USA',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56ab9fa207857e39ee7bb6',
          },
        ],
        orderId: '6932-8328-41388',
        totalQuantity: 28,
        shipments: [],
        payments: [],
        createdAt: '2020-02-26T17:32:15.734Z',
        updatedAt: '2020-02-26T17:32:15.734Z',
        __v: 0,
      },
      {
        _id: '5e56aaabca55187da0451838',
        customerUserId: 1004001,
        customerAccountId: '19a44deb-baf3-4e44-94f1-201d8e2565c4',
        orderTotal: 15.99,
        channel: 12,
        status: 'ORDER_CREATED',
        statusLog: 'Order has been placed',
        captureDate: '2020-02-21T00:00:00.000Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e56aaabca55187da0451839',
            lineItem: 1,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 25,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            id: '5e56aaabca55187da0451839',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e56aaabca55187da045183a',
            lineItem: 2,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 3,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            id: '5e56aaabca55187da045183a',
          },
        ],
        shipTo: [
          {
            _id: '5e56aaabca55187da045183b',
            shipToId: 100001,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.com',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Vancouver',
            state: 'BC',
            country: 'Canada',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56aaabca55187da045183b',
          },
          {
            _id: '5e56aaabca55187da045183c',
            shipToId: 100002,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.com',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Settle',
            state: 'WA',
            country: 'USA',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56aaabca55187da045183c',
          },
        ],
        orderId: '5726-6954-14672',
        totalQuantity: 28,
        shipments: [],
        payments: [],
        createdAt: '2020-02-26T17:28:11.353Z',
        updatedAt: '2020-02-26T17:28:11.353Z',
        __v: 0,
      },
      {
        _id: '5e56aa83ca55187da0451828',
        customerUserId: 1000001,
        customerAccountId: '19a44deb-baf3-4e44-94f1-201d8e2565c4',
        orderTotal: 15.99,
        channel: 12,
        status: 'ORDER_CANCELLED',
        statusLog: 'Cancelled',
        captureDate: '2020-02-21T00:00:00.000Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e56aa83ca55187da0451829',
            lineItem: 1,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 25,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            id: '5e56aa83ca55187da0451829',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e56aa83ca55187da045182a',
            lineItem: 2,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 3,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            id: '5e56aa83ca55187da045182a',
          },
        ],
        shipTo: [
          {
            _id: '5e56aa83ca55187da045182b',
            shipToId: 100001,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.com',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Vancouver',
            state: 'BC',
            country: 'Canada',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56aa83ca55187da045182b',
          },
        ],
        shipments: [
          {
            _id: '5e56ae8d9c86927fb511b756',
            shipToId: 100001,
            trackingNumber: '2345367890876543',
            lineItems: [
              {
                _id: '5e56ae8d9c86927fb511b757',
                lineItem: 1,
                quantity: 2,
                id: '5e56ae8d9c86927fb511b757',
              },
              {
                _id: '5e56ae8d9c86927fb511b758',
                lineItem: 2,
                quantity: 1,
                id: '5e56ae8d9c86927fb511b758',
              },
            ],
            shipmentStatus: 'awaiting shipping',
            estimatedDeliveryDate: '2019-03-01T00:00:00.000Z',
            taxCode: 'FR030000',
            id: '5e56ae8d9c86927fb511b756',
          },
        ],
        payments: [
          {
            shipToId: [10001, 1100002],
            _id: '5e56ae8d9c86927fb511b755',
            paymentIdentifier: '349f2abd-2b0d-4889-9b16-793d2ed31f6d',
            paymentMethod: 'Visa',
            amount: 15.99,
            currency: 'USD',
            conversion: '1.05',
            paymentDetails: '{}',
            paymentStatus: 'On Hold',
            id: '5e56ae8d9c86927fb511b755',
          },
        ],
        orderId: '6216-3474-79599',
        totalQuantity: 28,
        createdAt: '2020-02-26T17:27:31.344Z',
        updatedAt: '2020-02-26T17:44:45.766Z',
        __v: 0,
        revision: 1,
      },
      {
        _id: '5e56aa75ca55187da0451818',
        customerUserId: 1000001,
        customerAccountId: '19a44deb-baf3-4e44-94f1-201d8e2565c4',
        orderTotal: 15.99,
        channel: 12,
        status: 'ORDER_CREATED',
        statusLog: 'Order has been placed',
        captureDate: '2020-02-21T00:00:00.000Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e56aa75ca55187da0451819',
            lineItem: 1,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 25,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            id: '5e56aa75ca55187da0451819',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e56aa75ca55187da045181a',
            lineItem: 2,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 3,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            id: '5e56aa75ca55187da045181a',
          },
        ],
        shipTo: [
          {
            _id: '5e56aa75ca55187da045181b',
            shipToId: 100001,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.com',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Vancouver',
            state: 'BC',
            country: 'Canada',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56aa75ca55187da045181b',
          },
        ],
        shipments: [
          {
            _id: '5e56aa75ca55187da045181c',
            shipToId: 100001,
            trackingNumber: '2345367890876543',
            lineItems: [
              {
                _id: '5e56aa75ca55187da045181d',
                lineItem: 1,
                quantity: 2,
                id: '5e56aa75ca55187da045181d',
              },
              {
                _id: '5e56aa75ca55187da045181e',
                lineItem: 2,
                quantity: 1,
                id: '5e56aa75ca55187da045181e',
              },
            ],
            shipmentStatus: 'awaiting shipping',
            estimatedDeliveryDate: '2019-03-01T00:00:00.000Z',
            id: '5e56aa75ca55187da045181c',
          },
        ],
        payments: [
          {
            shipToId: [10001, 1100002],
            _id: '5e56aa75ca55187da045181f',
            paymentIdentifier: '349f2abd-2b0d-4889-9b16-793d2ed31f6d',
            paymentMethod: 'Visa',
            amount: 15.99,
            currency: 'USD',
            conversion: '1.05',
            paymentDetails: '{}',
            paymentStatus: 'On Hold',
            id: '5e56aa75ca55187da045181f',
          },
        ],
        orderId: '2751-9136-31078',
        totalQuantity: 28,
        createdAt: '2020-02-26T17:27:17.734Z',
        updatedAt: '2020-02-26T17:27:17.734Z',
        __v: 0,
      },
      {
        _id: '5e56aa6dca55187da0451808',
        customerUserId: 1000001,
        customerAccountId: '19a44deb-baf3-4e44-94f1-201d8e2565c4',
        orderTotal: 15.99,
        channel: 12,
        status: 'ORDER_CREATED',
        statusLog: 'Order has been placed',
        captureDate: '2020-02-21T00:00:00.000Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e56aa6dca55187da0451809',
            lineItem: 1,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 25,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            id: '5e56aa6dca55187da0451809',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e56aa6dca55187da045180a',
            lineItem: 2,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 3,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            id: '5e56aa6dca55187da045180a',
          },
        ],
        shipTo: [
          {
            _id: '5e56aa6dca55187da045180b',
            shipToId: 100001,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.com',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Vancouver',
            state: 'BC',
            country: 'Canada',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56aa6dca55187da045180b',
          },
        ],
        shipments: [
          {
            _id: '5e56aa6dca55187da045180c',
            shipToId: 100001,
            trackingNumber: '2345367890876543',
            lineItems: [
              {
                _id: '5e56aa6dca55187da045180d',
                lineItem: 1,
                quantity: 2,
                id: '5e56aa6dca55187da045180d',
              },
              {
                _id: '5e56aa6dca55187da045180e',
                lineItem: 2,
                quantity: 1,
                id: '5e56aa6dca55187da045180e',
              },
            ],
            shipmentStatus: 'awaiting shipping',
            estimatedDeliveryDate: '2019-03-01T00:00:00.000Z',
            id: '5e56aa6dca55187da045180c',
          },
        ],
        payments: [
          {
            shipToId: [10001, 1100002],
            _id: '5e56aa6dca55187da045180f',
            paymentIdentifier: '349f2abd-2b0d-4889-9b16-793d2ed31f6d',
            paymentMethod: 'Visa',
            amount: 15.99,
            currency: 'USD',
            conversion: '1.05',
            paymentDetails: '{}',
            paymentStatus: 'On Hold',
            id: '5e56aa6dca55187da045180f',
          },
        ],
        orderId: '7983-1684-55531',
        totalQuantity: 28,
        createdAt: '2020-02-26T17:27:09.895Z',
        updatedAt: '2020-02-26T17:27:09.895Z',
        __v: 0,
      },
      {
        _id: '5e56aa67ca55187da04517f8',
        customerUserId: 1000001,
        customerAccountId: '19a44deb-baf3-4e44-94f1-201d8e2565c4',
        orderTotal: 15.99,
        channel: 12,
        status: 'ORDER_CREATED',
        statusLog: 'Order has been placed',
        captureDate: '2020-02-21T00:00:00.000Z',
        items: [
          {
            discountId: ['100001', '100002'],
            _id: '5e56aa67ca55187da04517f9',
            lineItem: 1,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 25,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            id: '5e56aa67ca55187da04517f9',
          },
          {
            discountId: ['100001', '100002'],
            _id: '5e56aa67ca55187da04517fa',
            lineItem: 2,
            itemId: 100001,
            sku: '1538910',
            title: 'spinning chair',
            quantity: 3,
            price: 55.99,
            discount: 1.51,
            currency: 'USD',
            shipToId: 1000001,
            estimatedTax: 0.4,
            committedTax: 0.4,
            total: 6.9,
            id: '5e56aa67ca55187da04517fa',
          },
        ],
        shipTo: [
          {
            _id: '5e56aa67ca55187da04517fb',
            shipToId: 100001,
            fullName: 'John Smith',
            email: 'johnsmith@gmail.com',
            phone: '555-555-5555',
            street1: '2015 Main Street',
            street2: '',
            city: 'Vancouver',
            state: 'BC',
            country: 'Canada',
            zipCode: 'V6B0M5',
            kind: 'ship to address',
            shipmentAmount: 2.99,
            shipmentCarrier: 'Fedex',
            shipmentMethod: 'Express',
            shipmentMethodId: 1000001,
            promisedDeliveryDate: '2019-03-01T00:00:00.000Z',
            shipmentInstructions: 'Leave order at door',
            id: '5e56aa67ca55187da04517fb',
          },
        ],
        shipments: [
          {
            _id: '5e56aa67ca55187da04517fc',
            shipToId: 100001,
            trackingNumber: '2345367890876543',
            lineItems: [
              {
                _id: '5e56aa67ca55187da04517fd',
                lineItem: 1,
                quantity: 2,
                id: '5e56aa67ca55187da04517fd',
              },
              {
                _id: '5e56aa67ca55187da04517fe',
                lineItem: 2,
                quantity: 1,
                id: '5e56aa67ca55187da04517fe',
              },
            ],
            shipmentStatus: 'awaiting shipping',
            estimatedDeliveryDate: '2019-03-01T00:00:00.000Z',
            id: '5e56aa67ca55187da04517fc',
          },
        ],
        payments: [
          {
            shipToId: [10001, 1100002],
            _id: '5e56aa67ca55187da04517ff',
            paymentIdentifier: '349f2abd-2b0d-4889-9b16-793d2ed31f6d',
            paymentMethod: 'Visa',
            amount: 15.99,
            currency: 'USD',
            conversion: '1.05',
            paymentDetails: '{}',
            paymentStatus: 'On Hold',
            id: '5e56aa67ca55187da04517ff',
          },
        ],
        orderId: '4302-6879-57831',
        totalQuantity: 28,
        createdAt: '2020-02-26T17:27:03.548Z',
        updatedAt: '2020-02-26T17:27:03.548Z',
        __v: 0,
      },
    ],
  },
};

export const cancelStatus = { status: 'SUCCESS' };

export const dummyCancelOrder = {
  status: 'SUCCESS',
  dummyRequest: true,
};

export const dummyExportOrders = {
  status: 'success',
  urls: [
    {
      status: 'fulfilled',
      value:
        'https://raw.githubusercontent.com/FBosler/Medium-Data-Extraction/master/sales_team.csv',
    },
  ],
};
