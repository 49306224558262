import useFeatureFlag from 'hooks/useFeatureFlag';
import { FEATURE_FLAGS } from './general-config';
import DS3App from './pages/app';
import DS4App from './ds4/pages/app';
import GenericPageLoader from './ds4/components/GenericPageLoader';
import { updateV2Client } from 'ds4/service/clientV2';
import { updateV3Client } from 'ds4/service/clientV3';

const DS4Switch = () => {
  const { active, isFetching } = useFeatureFlag(FEATURE_FLAGS.DS4);

  // show loader fetching ds4_oms feature flag
  if (isFetching) {
    return <GenericPageLoader />;
  }

  updateV2Client();
  updateV3Client();

  return active ? <DS4App /> : <DS3App />;
};
export default DS4Switch;
