import { Suspense, lazy } from 'react';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from 'react-router-dom';
import { APP } from 'ds4/data';

const DateTimePickerCss = lazy(() => import('../components/date-picker'));

const OrdersLanding = lazy(() => import('modules/OrdersLanding'));
const OrderDetails = lazy(() => import('modules/OrderDetails'));

const InventoryLanding = lazy(() => import('modules/InventoriesLanding'));
const InventoryDetails = lazy(() => import('modules/InventoryDetails'));

const WarehouseLanding = lazy(() => import('modules/Warehouse'));

const History = lazy(() => import('modules/History'));
const SettingsLanding = lazy(() => import('modules/Settings'));

import SideNavigation from 'components/navigation';

const MainLayout = () => {
  return (
    <div style={{ display: 'flex' }}>
      <SideNavigation />
      <Outlet /> {/* Render matched child route components here */}
    </div>
  );
};

export const routes = createRoutesFromElements(
  <Route element={<MainLayout />}>
    {/* Orders Landing */}
    <Route element={<OrdersLanding />} path={`/${APP}/${APP}`} />
    <Route element={<Navigate to={`/${APP}/${APP}`} />} path={`/${APP}`} />
    <Route
      element={<Navigate to={`/${APP}/${APP}`} />}
      path={`/${APP}/manage-${APP}`}
    />
    {/* Orders Landing */}

    {/* Order Details */}
    <Route element={<OrderDetails />} path={`/${APP}/${APP}/:orderId`} />
    {/* Order Details */}

    {/* Inventories Landing */}
    <Route element={<InventoryLanding />} path={`/${APP}/inventory`} />
    {/* Inventories Landing */}

    {/* Inventory Details */}
    <Route element={<InventoryDetails />} path={`/${APP}/inventory/:itemId`} />
    {/* Inventory Details */}

    {/* Warehouse landing */}
    <Route element={<WarehouseLanding />} path={`/${APP}/warehouse`} />
    {/* Warehouse landing */}

    {/* History */}
    <Route element={<History />} path={`/${APP}/history`} />
    {/* History */}

    {/* Settings Landing */}
    <Route element={<SettingsLanding />} path={`/${APP}/settings`} />
    {/* Settings Landing */}
  </Route>
);

const router = createBrowserRouter(routes);

const AppRoutes = () => {
  return (
    <Suspense fallback={'Loading...'}>
      <RouterProvider router={router} />
      <DateTimePickerCss />
    </Suspense>
  );
};

export default AppRoutes;
