import { Snackbar } from '@teamfabric/copilot-ui';
import styled from 'styled-components';

export const StyledToast = styled(Snackbar)`
  padding-top: 14px;
  padding-bottom: 14px;
  .label {
    margin-right: 19px;
    font-size: 13px;
  }
`;
