import { calculateOrderSubtotalCost, sum } from './number';
import has from 'lodash/has';
import get from 'lodash/get';

const getPaymenForShipToId = (order, shipToId) => {
  const payments = [];

  if (!has(order, 'payments[0]')) return payments;

  order.payments.forEach(item => {
    if (item.shipToId.includes(shipToId)) {
      let street = item.billToAddress.street1;

      if (has(item, 'billToAddress.street2')) {
        street += ` ${item.billToAddress.street2}`;
      }

      let billToName = '';
      const nameArray = ['first', 'middle', 'last'];

      nameArray.forEach(elem => {
        if (
          has(item, `[billToAddress][name][${elem}]`) &&
          item.billToAddress.name[elem].length
        ) {
          billToName += item.billToAddress.name[elem] + ' ';
        }
      });

      payments.push({
        paymentIdentifier: item.paymentIdentifier,
        billToId: item.billToId,
        paymentMethod: item.paymentMethod,
        amount: item.amount,
        currency: item.currency,
        paymentStatus: item.paymentStatus,
        address: {
          email: get(item, 'billToAddress.email', ''),
          phone: get(item, 'billToAddress.phone.number', ''),
          street: street,
          city: item.billToAddress.city,
          state: item.billToAddress.state,
          zip: item.billToAddress.zipCode,
          country: item.billToAddress.country,
          name: billToName,
        },
      });
    }
  });

  return payments;
};

const getShipToName = ({ ship }) => {
  let shipToName = '';
  const nameArray = ['first', 'middle', 'last'];

  nameArray.forEach(elem => {
    if (
      has(ship, `[address][name][${elem}]`) &&
      ship.address.name[elem].length
    ) {
      shipToName += capitalizeFirstLetter(ship.address.name[elem]) + ' ';
    } else if (
      has(ship, `[pickupPerson][name][${elem}]`) &&
      ship.pickupPerson.name[elem].length
    ) {
      shipToName += capitalizeFirstLetter(ship.pickupPerson.name[elem]) + ' ';
    }
  });

  return shipToName;
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const getShipToAddress = order => {
  const shipToAddress = order.shipTo.map(ship => {
    let street = ship?.address?.street1 ? ship?.address?.street1 : '';

    if (has(ship, 'address.street2')) {
      street += ` ${ship.address.street2}`;
    }

    let shipToName = getShipToName({ ship });

    return {
      id: ship.shipToId,
      address: {
        email: get(ship, 'address.email', '')
          ? get(ship, 'address.email', '')
          : get(ship, 'pickupPerson.email', ''),
        phone: get(ship, 'address.phone.number', '')
          ? get(ship, 'address.phone.number', '')
          : get(ship, 'pickupPerson.phone.number', ''),
        street: street,
        city: ship?.address?.city ? ship?.address?.city : '',
        state: ship?.address?.state ? ship?.address?.state : '',
        zip: ship?.address?.zipCode ? ship?.address?.zipCode : '',
        country: ship?.address?.country ? ship?.address?.country : '',
        name: shipToName,
      },
      method: `${ship.shipmentCarrier} ${ship.shipmentMethod}`,
      shipments: [],
      remainingItems: [],
      payments: getPaymenForShipToId(order, ship.shipToId),
    };
  });

  return shipToAddress;
};

const getItemCategories = (itemId, items) => {
  const itemDetails = items.find(i => i.itemId === itemId);
  const itemCategory = itemDetails ? itemDetails.group.map(i => i.name) : [];

  return itemCategory.join(', ');
};

const getItemImage = (itemId, items) => {
  const itemDetails = items.find(i => i.itemId === itemId);

  if (has(itemDetails, 'images[0].source[0].url')) {
    return itemDetails.images[0].source[0].url;
  }

  return null;
};

const getShipToShippedItems = ({ order, items, shipToId }) => {
  const shipmentForAddress = order.shipments.filter(
    s => s.shipToId === shipToId
  );

  const result = [];

  shipmentForAddress.forEach(shipment => {
    const itemsResult = [];
    let totalPrice = 0;
    let totalQuantity = 0;

    shipment.lineItems.forEach(elem => {
      const item = order.items.find(i => i.lineItemId === elem.lineItemId);

      if (item) {
        const shippedItem = {
          name: item.title,
          itemId: item.itemId,
          lineItemId: item.lineItemId,
          sku: item.sku,
          category: getItemCategories(item.itemId, items),
          image: getItemImage(item.itemId, items),
          price: item.price,
          quantity: elem.quantity,
          totalPrice: parseFloat((elem.quantity * item.price).toFixed(2)),
          attributes: item.attributes || [],
        };

        itemsResult.push(shippedItem);
        totalPrice += shippedItem.totalPrice;
        totalQuantity += shippedItem.quantity;
      }
    });

    result.push({
      items: itemsResult,
      totalPrice: parseFloat(totalPrice.toFixed(2)),
      totalQuantity,
      trackingNumber: shipment.trackingNumber,
      shipmentCarrier: shipment.shipmentCarrier,
      shipmentCarrierUrl: shipment.shipmentCarrierUrl,
      shipmentStatus: shipment.shipmentStatus,
      estimatedDeliveryDate: shipment.estimatedDeliveryDate,
    });
  });

  return result;
};

const getShipToPendingShipment = ({ order, items, shipToId, shippedItems }) => {
  let totalPrice = 0;
  let totalQuantity = 0;

  let remainingItems = order.items
    .filter(item => item.shipToId === shipToId)
    .map(item => {
      return {
        name: item.title,
        itemId: item.itemId,
        lineItemId: item.lineItemId,
        sku: item.sku,
        category: getItemCategories(item.itemId, items),
        image: getItemImage(item.itemId, items),
        price: item.price,
        quantity: item.quantity,
        attributes: item.attributes || [],
      };
    });

  shippedItems.forEach(shippedItem => {
    shippedItem.items.forEach(shippedItem => {
      remainingItems = updateItemsShipped(remainingItems, shippedItem);
    });
  });

  remainingItems = remainingItems
    .filter(item => {
      return item.quantity > 0;
    })
    .map(item => {
      item.totalPrice = parseFloat((item.quantity * item.price).toFixed(2));

      totalPrice += item.totalPrice;
      totalQuantity += item.quantity;

      return item;
    });

  return { items: remainingItems, totalPrice, totalQuantity };
};

const updateItemsShipped = (itemsForAddress, shippedItem) => {
  return itemsForAddress.map(item => {
    if (
      item.itemId === shippedItem.itemId &&
      item.lineItemId === shippedItem.lineItemId
    ) {
      item.quantity = item.quantity - shippedItem.quantity;
    }

    return item;
  });
};

const getShipments = (shipToData, order, items) => {
  let result = [];

  result = shipToData.map(shipToItem => {
    let shippedItems = getShipToShippedItems({
      order,
      items,
      shipToId: shipToItem.id,
    });

    let remainingItems = getShipToPendingShipment({
      order,
      items,
      shipToId: shipToItem.id,
      shippedItems,
    });

    shippedItems = shippedItems.map(shippedItem => {
      shippedItem.items = shippedItem.items.map(item => {
        delete item.itemId;
        delete item.lineItemId;
        return item;
      });

      return shippedItem;
    });

    shipToItem.shipments = shippedItems;
    shipToItem.remainingItems = remainingItems;

    return shipToItem;
  });

  return result;
};

export const mapOrderData = (order, items, channels) => {
  const channelName = channels.find(c => c.value === order.channel);
  const subtotalCost = calculateOrderSubtotalCost(order.items);
  const shippingCost = sum(order.shipTo, 'price');
  let shipToData = getShipToAddress(order);
  let descriptions = (order && order.statusDescriptions) || [];

  const {
    orderId,
    customerEmail,
    status,
    createdAt,
    updatedAt,
    channel,
    taxTotal,
    orderTotal,
    attributes,
  } = order;

  const orderItem = {
    orderId,
    customerEmail,
    status,
    date: new Date(createdAt),
    updatedDate: new Date(updatedAt),
    channel: channelName ? channelName.label : `Channel ID ${channel}`,
    subtotal: subtotalCost,
    taxes: taxTotal,
    shipping: shippingCost,
    total: orderTotal,
    shipTo: [],
    descriptions: descriptions,
    orderAttributes: attributes,
  };

  orderItem.shipTo = getShipments(shipToData, order, items);

  return orderItem;
};
