import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigation } from '@teamfabric/copilot-ui';
import { StyledNavigation } from './style';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FEATURE_FLAGS } from './../../general-config';
import { navLinks } from './constant';
import { useNavigate } from 'react-router-dom';

export const useNavigationLinks = () => {
  const shouldHideDs3Nav = useFeatureFlag(FEATURE_FLAGS.HIDE_DS3_NAV);
  return useMemo(() => {
    if (shouldHideDs3Nav?.active || shouldHideDs3Nav?.isFetching) {
      return [];
    }
    return navLinks;
  }, [shouldHideDs3Nav?.active, shouldHideDs3Nav?.isFetching]);
};

const getActiveLinks = (links, url) => {
  return [...links].map(link => {
    if (link.children) {
      const childLinks = link.children.map(sublink => {
        return sublink.url === url
          ? { ...sublink, active: true }
          : { ...sublink, active: false };
      });
      return { ...link, children: childLinks };
    }
    return link.url === url
      ? { ...link, active: true }
      : { ...link, active: false };
  });
};

const SideNavigation = () => {
  const computedLinks = useNavigationLinks();
  const navigate = useNavigate();
  const [links, setLinks] = useState(
    getActiveLinks(computedLinks, window.location.pathname)
  );

  useEffect(() => {
    const _links = getActiveLinks(computedLinks, window.location.pathname);
    setLinks(_links);
  }, [computedLinks]);

  const setActiveTab = url => {
    const updatedLinks = getActiveLinks(links, url);
    setLinks(updatedLinks);
  };

  const clickHandler = useCallback(
    event => {
      event.preventDefault();
      if (event.target.pathname) {
        navigate(event.target.pathname);
        setActiveTab(event.target.pathname);
      }
    },
    [setActiveTab]
  );

  return (
    <StyledNavigation>
      <div className='side-navigation'>
        <Navigation
          className='secondary'
          links={links}
          onClick={clickHandler}
          orientation='vertical'
        />
      </div>
    </StyledNavigation>
  );
};

export default SideNavigation;
