import { safeJsonParse } from 'lib/utils/safeJsonParse';

export const FEATURE_FLAGS = Object.freeze({
  OMS_V1: 'ui-v1', // unleash flag
  DS4: 'ds4_oms',
  HIDE_DS3_NAV: 'ds3-hide-oms-internal-nav',
});

export const CONFIG = Object.freeze({
  GITLAB_PROJECT_ID: process.env.OMS_GITLAB_PROJECT_ID,
  GITLAB_TOKEN: process.env.OMS_GITLAB_TOKEN,
});

export const PIM_API_KEY_MAP = Object.freeze(
  safeJsonParse(process.env.PIM_API_KEY_MAP, {})
);
