import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import getApplicationStage from '../lib/utils/getApplicationStage';

const isBrowser = typeof window !== 'undefined';

export const API_URL = Object.freeze({
  // Warehouse APIs
  WAREHOUSE_LIST: (offset = 0, limit = 10) =>
    `/warehouse?offset=${offset}&limit=${limit}`,
  WAREHOUSE_CREATE: () => '/warehouse',
  WAREHOUSE_QUERY: () => '/warehouse/query',
  WAREHOUSE_UPDATE: id => `/warehouse/${id}`,
  WAREHOUSE_CHANNELS: () => '/warehouse/channels',
  WAREHOUSE_CHANNELS_STATUS: () => '/warehouse/channels/status',

  // Inventory APIs
  UPDATE_INVENTORY: () => `/`,
  INVENTORY_LIST: () => `/inventory/query`,
  INVENTORY_SKU_LIST: () => '/inventory/sku-list',
  INVENTORY_ITEM_IDS: () => '/inventory/item-ids',
  INVENTORY_UPLOAD: () => '/inventory/upload-url',
  UPLOAD_FILE_STATUS_POLLING: keyId => `/inventory/bulk-upload-status/${keyId}`,
  UPLOAD_FILE_HISTORY: (offset = 0, limit = 10) =>
    `/inventory/bulk-upload-status?offset=${offset}&limit=${limit}`,

  // Orders APIs
  ORDERS_LIST: () => '/orders/query',
  ORDER_BY_ID: id => `/orders/${id}`,
  ORDER_CANCEL: () => `/orders/cancel`,
  ORDER_RETURN: () => `/orders/return`,
  ORDER_ADD_SHIPMENT: () => `/orders/shipments`,
  ORDERS_EXPORT: () => '/orders/export',
  ORDERS_EXPORTS: () => '/',
  ORDERS_EXPORTS_STATUS: () => '/status',
  ORDERS_EXPORTS_DOWNLOAD: () => '/download-url',
  ORDERS_EXPORT_LOG: id => `/orders/export/log/${id}`,
  ORDERS_EXPORT_URL: id => `/orders/export/url/${id}`,
  ORDERS_IMPORT_LOG: id => `/orders/import/shipments/log/${id}`,
  ORDERS_IMPORT_TRACKING_ID: () => '/orders/shipments/upload-url',

  // Fulfillment APIs
  FULFILMENT_CREATE: '/fulfillment-method',
  FULFILMENT_UPDATE: id => `/fulfillment-method/${id}`,
  FULFILMENT_GET_BY_ID: id => `/fulfillment-method/${id}`,
  FULFILMENT_GET_ALL: `/fulfillment-method`,
  FULFILMENT_SET_PRIORITY: '/fulfillment-method/set-priority',
  FULFILMENT_SETTING: '/setting',

  // Cancelation & Return APIs
  RETURN_POLICY_URL: '/setting/return-policy',
  REFUND_POLICY_URL: '/setting/sugar-refund-policy',
  CANCELATION_POLICY_URL: '/setting/cancellation-policy',

  // Attributes APIs
  ATTRIBUTES_CREATE: () => `/attribute`,
  ATTRIBUTES_LIST: () => `attribute/getAll`,
  ATTRIBUTE_UPDATE: id => `attribute/${id}`,
  ATTRIBUTE_DELETE: id => `attribute/${id}`,

  //Shipping APIs
  SHIPPING_LIST: () => `shipping/all`,
  SHIPPING_GET_BY_ID: id => `shipping/${id}`,
  ADD_SHIPMENT: () => `shipping/create`,
  UPDATE_SHIPMENT: id => `shipping/${id}`,
  SHIPPING_CREATE: () => `shipping/create`,
  SHIPPING_DELETE: id => `shipping/${id}`,
  ADD_SHIPPING_ITEMS: () => `shipping/addItem`,
  GET_SHIPPING_ITEMS: id => `shipping/${id}/items`,
  REMOVE_SHIPPING_ITEMS: () => `shipping/removeItem`,

  //SEARCH Items
  SEARCH_ITEMS: () => `item/product/group`,

  //HISTORY API
  INVENTORY_HISTORY_LOGS: 'inventory/bulk-upload-status',
  ORDER_HISTORY_LOGS: 'orders/export-import/logs',

  // Address validation
  VALIDATE_ADDRESS: () => '/address/validate',

  // User identity related APIs
  AUTH_REFRESH_TOKEN: () => 'user/refresh-token',
});

export class ApiClient {
  domain = '';
  baseURL = '';
  private client: AxiosInstance = null;
  private retry;
  private readonly MAX_RETRY = 3;

  constructor(domain: string, config?: AxiosRequestConfig) {
    this.domain = domain;
    this.retry = 0;
    this.setBaseURL(domain);
    this.setupClient(config);
  }

  private setBaseURL(domain: string) {
    this.baseURL = `${window.location.origin}/api-${domain}`;
  }

  getHeaders() {
    return {
      'Authorization': isBrowser ? sessionStorage.getItem('accessToken') : '',
      'responseType': 'json',
      'x-site-context': JSON.stringify({
        date: new Date().toISOString(),
        channel: 12,
        account: window.localStorage.getItem('isLoggedInWithIdV2')
          ? window.sessionStorage.getItem('accountId')
          : window.sessionStorage.getItem('account'),
        stage: getApplicationStage(),
      }),
    };
  }

  getRetryCount() {
    return this.retry;
  }

  async getRefreshToken(error) {
    this.retry = this.retry + 1;
    const headers = this.getHeaders();
    const { data } = await axios
      .create({
        baseURL: window.location.origin + '/api-identity/',
        headers,
      })
      .post('auth/local/refresh', {
        refreshToken: localStorage.getItem('refreshToken'),
      });

    if (data.accessToken) {
      const { accessToken, refreshToken } = data;
      sessionStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      const request = {
        ...error.config,
        headers: {
          ...error.config.headers,
          Authorization: accessToken,
        },
      };
      return this.client.request(request);
    } else {
      return Promise.reject(error);
    }
  }

  private interceptRequest() {
    this.client.interceptors.request.use(request => {
      const accessToken = window?.sessionStorage.getItem('accessToken');
      const headers = this.getHeaders();
      if (accessToken) {
        request.headers.Authorization = accessToken;
      }
      request.headers['x-site-context'] = headers['x-site-context'];
      return request;
    });
  }

  private interceptResponse() {
    this.client.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        if (this.retry >= this.MAX_RETRY) {
          this.retry = 0;
          return Promise.reject(error);
        }

        if (error && error.response && error.response.status === 403) {
          try {
            return await this.getRefreshToken(error);
          } catch (err) {
            window.location.href = `${window.location.origin}/auth/login`;
          }
        }
        if (error && error.response && error.response.status === 500) {
          return error.response;
        }
        return Promise.reject(error);
      }
    );
  }

  private setupClient(config?: AxiosRequestConfig) {
    const headers = this.getHeaders();
    this.client = axios.create({
      baseURL: this.baseURL,
      responseType: 'json',
      headers,
      ...config,
    });
    this.interceptRequest();
    this.interceptResponse();
  }

  async get(
    url: string,
    config?: AxiosRequestConfig<any>
  ): Promise<AxiosResponse<any, any>> {
    try {
      return config
        ? await this.client.get(url, config)
        : await this.client.get(url);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async post(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<AxiosResponse<any, any>> {
    try {
      return config
        ? await this.client.post(url, data, config)
        : await this.client.post(url, data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async patch(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<AxiosResponse<any, any>> {
    try {
      return config
        ? await this.client.patch(url, data, config)
        : await this.client.patch(url, data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async put(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<AxiosResponse<any, any>> {
    try {
      return config
        ? await this.client.put(url, data, config)
        : await this.client.put(url, data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async delete(
    url: string,
    config?: AxiosRequestConfig<any>
  ): Promise<AxiosResponse<any, any>> {
    try {
      return config
        ? await this.client.delete(url, config)
        : await this.client.delete(url);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

const clientV1 = {
  identity: new ApiClient('indentity'),
  oms: new ApiClient('oms'),
  pim: new ApiClient('pim'),
};

export default clientV1;
