export const ACTIONS = {
  SET_INVENTORY_IMPORT_KEY: 'inventory.setInventoryImportKey',
};

export const setInventoryImportKey = keyId => {
  return {
    type: ACTIONS.SET_INVENTORY_IMPORT_KEY,
    keyId,
  };
};
