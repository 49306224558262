import React from 'react';
import { StyledCell } from '../../modules/History/style';

const ORDER_STATUS = {
  ORDER_CREATED: {
    name: 'Created',
    theme: 'statusOrange',
    bullet: 'orange-bullet',
    color: '#FD9F1C',
  },
  ORDER_CONFIRMED: {
    name: 'Confirmed',
    theme: 'statusOrange',
    bullet: 'orange-bullet',
    color: '#E84869',
  },
  ORDER_CANCELLED: {
    name: 'Cancelled',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#737F8F',
  },
  ORDER_PARTIALLY_SHIPPED: {
    name: 'Partially Shipped',
    theme: 'statusGreen',
    bullet: 'green-bullet',
    color: '#3D82FF',
  },
  ORDER_SHIPPED: {
    name: 'Shipped',
    theme: 'statusGreen',
    bullet: 'green-bullet',
    color: '#3D82FF',
  },
  ORDER_PARTIALLY_DELIVERED: {
    name: 'Partially Delivered',
    theme: 'statusBlue',
    bullet: 'blue-bullet',
    color: '#008A1E',
  },
  ORDER_DELIVERED: {
    name: 'Delivered',
    theme: 'statusBlue',
    bullet: 'blue-bullet',
    color: '#008A1E',
  },
  ORDER_RETURNED: {
    name: 'Returned',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#384454',
  },
  ORDER_PARTIALLY_RETURNED: {
    name: 'Partially Returned',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#384454',
  },
  ORDER_PAYMENT_AUTHORIZED: {
    name: 'Payment Authorized',
    theme: 'statusOrange',
    bullet: 'orange-bullet',
    color: '#8458F6',
  },
  ORDER_PAYMENT_INVALID: {
    name: 'Payment Invalid',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_REFUNDED: {
    name: 'Order Refunded',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_PARTIALLY_FULFILLED: {
    name: 'Order Partially Fulfilled',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_FULFILLED: {
    name: 'Order Fulfilled',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_PARTIALLY_ALLOCATED: {
    name: 'Order Partially Allocated',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_ALLOCATED: {
    name: 'Order Allocated',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_PICKED_UP: {
    name: 'Order Picked Up',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_PARTIALLY_PICKED_UP: {
    name: 'Order Partially Picked Up',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_PARTIALLY_EXCHANGED: {
    name: 'Order Partially Exchanged',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_EXCHANGED: {
    name: 'Order Exchanged',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_PARTIALLY_CANCELLED: {
    name: 'Order Partially Cancelled',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_HOLD: {
    name: 'Order Hold',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_HOLD_FRAUD: {
    name: 'Order Hold Fraud',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_HOLD_CSR: {
    name: 'Order Hold Csr',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_HOLD_CROSSBORDER: {
    name: 'Order Hold Crossborder',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_HOLD_REVALIDATION: {
    name: 'Order Hold Revalidation',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
  ORDER_ERROR: {
    name: 'Order Error',
    theme: 'statusGrey',
    bullet: 'grey-bullet',
    color: '#D50000',
  },
};

const ORDER_ACTIONS = { cancel: { name: 'Cancel', enable: false } };

let ORDER_STATUS_ARRAY = [];
Object.keys(ORDER_STATUS).forEach(key => {
  ORDER_STATUS_ARRAY.push({ name: ORDER_STATUS[key].name, value: key });
});

const IMPORT_HISTORY_STATUS = {
  PROCESSING: {
    name: 'Uploading',
    theme: 'statusOrange',
    bullet: 'orange-bullet',
  },
  FINISHED: {
    name: 'Done',
    theme: 'statusGreen',
    bullet: 'green-bullet',
  },
  FILE_ERROR: {
    name: 'Error',
    theme: 'statusRed',
    bullet: 'red-bullet',
  },
  SERVER_ERROR: {
    name: 'Error',
    theme: 'statusRed',
    bullet: 'red-bullet',
  },
};
const CANCEL_OPTIONS = [
  { text: 'Merchant Out of Stock', code: '1001' },
  { text: 'Customer is no longer interested in product', code: '2001' },
  { text: 'Customer found better price elsewhere', code: '2002' },
  { text: 'Change of heart', code: '2003' },
  { text: 'Purchased by accident', code: '2004' },
  { text: 'Customer is not eligible', code: '1002' },
  { text: 'Customer Address is incomplete', code: '1003' },
  { text: 'Fraud', code: '1004' },
  { text: 'Item Description Incorrect', code: '2005' },
  { text: 'Purchased by Mistake', code: '2006' },
  { text: 'Product will not arrive in time to when I need it', code: '2007' },
  { text: 'Other', code: '9999' },
];

const ALLOWED_CANCELLATION = ['ORDER_PAYMENT_AUTHORIZED', 'ORDER_CREATED'];

const INVENTORY_IMPORT_HEADERS = [
  [
    'Sku',
    'Total Quantity',
    'Reserved Quantity',
    'Location Id',
    'Type',
    'Channel Id',
    'Transit Ref',
    'Transit Order Date',
    'Transit Stock Date',
    'ItemId',
  ],
];

const ORDER_IMPORT_HEADERS = [
  [
    'Order Id',
    'Line Item Id',
    'Shipment Ref',
    'Shipment Carrier',
    'Shipment Carrier Url',
    'Tracking Number',
    'Quantity',
    'Estimated Delivery Date',
    'Shipment Status',
  ],
];

const WAREHOUSE_TABLE_COLUMN_HEADERS = [
  {
    accessor: 'id',
    isSortable: false,
    title: 'Warehouse ID',
  },
  {
    accessor: 'name',
    isSortable: false,
    title: 'Warehouse name',
  },
  {
    accessor: 'warehouseAddress',
    isSortable: false,
    title: 'Warehouse address',
  },
  {
    accessor: 'channels',
    isSortable: true,
    title: 'Channels',
  },
  {
    accessor: 'status',
    isSortable: true,
    title: 'Status',
  },
];

const ORDERS_TABLE_COLUMN_HEADERS = [
  {
    accessor: 'orderId',
    isSortable: false,
    title: 'Order ID',
  },
  {
    accessor: 'customerName',
    isSortable: false,
    title: 'Customer Name',
  },
  {
    accessor: 'orderTotal',
    title: 'Value',
  },
  {
    accessor: 'status',
    title: 'Status',
  },
  {
    accessor: 'createdAt',
    title: 'Order Date',
  },
];
const INVENTORY_TABLE_COLUMN_HEADERS = [
  {
    accessor: 'name',
    isSortable: false,
    title: 'Item name',
  },
  {
    accessor: 'parentId',
    title: 'Product ID',
  },
  {
    accessor: 'sku',
    title: 'SKU ID',
  },
  {
    accessor: 'inStock',
    title: 'Available stock',
  },
  {
    accessor: 'inTransit',
    title: 'In-transit',
  },
];
const EMPTY_STATE_DATA = {
  ORDERS: {
    footerContent:
      '###### Learn more about OMS and Order fulfillment in our [Knowledge base](https://knowledgebase.fabric.inc/knowledgebase/oms/orders)',
    primaryText: 'You have no orders to fulfill',
    secondaryText:
      'Encourage your customers to purchase items by giving them discounts or free shipping',
  },
  INVENTORY: {
    footerContent:
      '###### Learn more about OMS and Inventory in our [Knowledge base](https://knowledgebase.fabric.inc/knowledgebase/oms/inventory)',
    primaryText: 'You have no inventory',
    secondaryText:
      'Start by adding your items and connecting them to warehouses and channels',
  },
  WAREHOUSE: {
    footerContent:
      '###### Learn more about OMS and Warehouse in our [Knowledge base](https://knowledgebase.fabric.inc/knowledgebase/oms/warehouse)',
    primaryText: 'You have no warehouse',
    secondaryText:
      'Start by adding your items and connecting them to warehouses and channels',
  },
};

const GLOBAL_HEADER_LINKS = [
  {
    href: '/overview',
    icon: 'overview',
    text: 'Overview',
  },
  {
    href: '/pim',
    icon: 'pim',
    text: 'PIM',
  },
  {
    href: '/xpm',
    icon: 'xpm',
    text: 'XPM',
  },
  {
    href: '/offers',
    icon: 'offers',
    text: 'OFFERS',
  },
  {
    active: true,
    href: '/oms',
    icon: 'oms',
    text: 'OMS',
  },
];

const SETTINGS_TAB = [
  { id: 1, name: 'Attributes', active: true },
  { id: 2, name: 'Fulfillment', active: false },
  { id: 3, name: 'Shipping and carrier', active: false },
  { id: 4, name: 'Return and cancellation', active: false },
];

const HISTORY_MENU = [
  { id: 1, name: 'Orders', active: true },
  { id: 2, name: 'Inventory', active: false },
];

const HISTORY_LOG = [
  {
    id: '28394-23234-2348392',
    type: 'export',
    status: 'Done',
    created: Date.now(),
    message: 'some message',
  },
  {
    id: '28394-23234-2348392',
    type: 'export',
    status: 'Done',
    created: Date.now(),
    message: 'some message',
  },
  {
    id: '28394-23234-2348392',
    type: 'export',
    status: 'Done',
    created: Date.now(),
    message: 'some message',
  },
  {
    id: '28394-23234-2348392',
    type: 'export',
    status: 'Done',
    created: Date.now(),
    message: 'some message',
  },
  {
    id: '28394-23234-2348392',
    type: 'export',
    status: 'Done',
    created: Date.now(),
    message: 'some message',
  },
  {
    id: '28394-23234-2348392',
    type: 'export',
    status: 'Done',
    created: Date.now(),
    message: 'some message',
  },
  {
    id: '28394-23234-2348392',
    type: 'export',
    status: 'Done',
    created: Date.now(),
    message: 'some message',
  },
];

const SHIPPING_MODEL = {
  name: '',
  description: '',
  taxCode: '',
  minimumDays: null,
  maximumDays: null,
  cutOffTime: null,
  cost: null,
  channel: [],
  addressType: ['PO', 'Business'],
  region: 'CA',
  weight: {
    min: null,
    max: null,
  },
  dimension: {
    min: {
      height: null,
      length: null,
      width: null,
    },
    max: {
      height: null,
      length: null,
      width: null,
    },
  },
  cartValue: {
    min: null,
    max: null,
  },
  configuredBy: 'byProducts',
};

const HISTORY_TABLE_COLUMN = [
  {
    accessor: 'icon',
    isSortable: false,
    title: '',
    children: [
      {
        accessor: 'icon',
        isSortable: false,
        title: '',
      },
      {
        accessor: 'logId',
        isSortable: false,
        title: 'Log ID',
      },
      {
        accessor: 'type',
        isSortable: false,
        title: 'Type',
      },
      {
        isSortable: false,
        accessor: 'status',
        title: 'Status',
      },
      {
        accessor: 'created',
        title: 'Created',
        isSortable: false,
      },
      {
        accessor: 'message',
        title: 'Message',
        isSortable: false,
        render: data => <StyledCell>{data.message}</StyledCell>,
      },
    ],
  },
  {
    accessor: 'logId',
    isSortable: false,

    title: 'Log ID',
  },
  {
    accessor: 'type',
    isSortable: false,

    title: 'Type',
  },
  {
    isSortable: false,
    accessor: 'status',

    title: 'Status',
  },
  {
    accessor: 'created',
    title: 'Created',

    isSortable: false,
  },
  {
    accessor: 'message',
    title: 'Message',

    isSortable: false,
    render: data => <StyledCell>{data.message}</StyledCell>,
  },
];

const DUMMY_ORDER_TABLDE_DATA = [
  {
    orderId: '5271-5400-29997',
    customerName: 'John P Smith ',
    value: '$17866.33',
    status: 'Created',
    orderDate: '2021-06-08T07:42:11.817Z',
    children: [],
    menu: ['item1', 'item2', 'item3'],
  },
  {
    orderId: '6300-2447-94561',
    customerName: 'John P Smith ',
    value: '$1896666.33',
    status: 'Created',
    orderDate: '2021-06-08T07:42:11.817Z',
    children: [],
    menu: ['item1', 'item2', 'item3'],
  },
  {
    orderId: '1734-7179-64824',
    customerName: 'John P Smith ',
    value: '$125.33',
    status: 'Created',
    orderDate: '2021-06-08T07:42:11.806Z',
    children: [],
    menu: ['item1', 'item2', 'item3'],
  },
  {
    orderId: '2872-1611-50985',
    customerName: 'John P Smith ',
    value: '$1290.33',
    status: 'Created',
    orderDate: '2021-06-08T07:42:11.785Z',
    children: [],
    menu: ['item1', 'item2', 'item3'],
  },
  {
    orderId: '6044-5582-62136',
    customerName: 'John P Smith ',
    value: '$12125.33',
    status: 'Created',
    orderDate: '2021-06-08T07:42:11.776Z',
    children: [],
    menu: ['item1', 'item2', 'item3'],
  },
  {
    orderId: '3221-5429-41308',
    customerName: 'John P Smith ',
    value: '$125907.33',
    status: 'Created',
    orderDate: '2021-06-08T07:42:11.722Z',
    children: [],
    menu: ['item1', 'item2', 'item3'],
  },
  {
    orderId: '5789-9689-91084',
    customerName: 'John P Smith ',
    value: '$12455.33',
    status: 'Created',
    orderDate: '2021-06-08T07:42:11.699Z',
    children: [],
    menu: ['item1', 'item2', 'item3'],
  },
  {
    orderId: '1452-5235-69880',
    customerName: 'John P Smith ',
    value: '$12125.33',
    status: 'Created',
    orderDate: '2021-06-08T07:42:11.670Z',
    children: [],
    menu: ['item1', 'item2', 'item3'],
  },
  {
    orderId: '4263-9579-97309',
    customerName: 'John P Smith ',
    value: '$12565.33',
    status: 'Created',
    orderDate: '2021-06-08T07:42:11.597Z',
    children: [],
    menu: ['item1', 'item2', 'item3'],
  },
  {
    orderId: '8577-8046-19120',
    customerName: 'John P Smith ',
    value: '$18925.33',
    status: 'Created',
    orderDate: '2021-06-08T07:42:11.585Z',
    children: [],
    menu: ['item1', 'item2', 'item3'],
  },
];

const COUNTRIES = [
  { value: 'USA', label: 'USA' },
  { value: 'CA', label: 'Canada' },
];

const STATES = {
  usa: [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ],
  canada: [
    { value: 'AB', label: 'Alberta' },
    { value: 'BC', label: 'British Columbia' },
    { value: 'MB', label: 'Manitoba' },
    { value: 'NB', label: 'New Brunswick' },
    { value: 'NL', label: 'Newfoundland and Labrador' },
    { value: 'NT', label: 'Northwest Territories' },
    { value: 'NS', label: 'Nova Scotia' },
    { value: 'NU', label: 'Nunavut' },
    { value: 'ON', label: 'Ontario' },
    { value: 'PE', label: 'Prince Edward Island' },
    { value: 'QC', label: 'Quebec' },
    { value: 'SK', label: 'Saskatchewan' },
    { value: 'YT', label: 'Yukon' },
  ],
};

export {
  ORDER_STATUS,
  ORDER_STATUS_ARRAY,
  IMPORT_HISTORY_STATUS,
  ORDER_ACTIONS,
  CANCEL_OPTIONS,
  ALLOWED_CANCELLATION,
  INVENTORY_IMPORT_HEADERS,
  ORDER_IMPORT_HEADERS,
  ORDERS_TABLE_COLUMN_HEADERS,
  EMPTY_STATE_DATA,
  GLOBAL_HEADER_LINKS,
  INVENTORY_TABLE_COLUMN_HEADERS,
  WAREHOUSE_TABLE_COLUMN_HEADERS,
  SETTINGS_TAB,
  HISTORY_MENU,
  HISTORY_LOG,
  HISTORY_TABLE_COLUMN,
  SHIPPING_MODEL,
  DUMMY_ORDER_TABLDE_DATA,
  COUNTRIES,
  STATES,
};
