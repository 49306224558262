export enum EnvironmentScopeEnum {
  'production' = 'production',
  'staging' = 'staging',
  'sandbox' = 'sandbox',
  'development' = 'development',
}
export type EnvironmentScope = EnvironmentScopeEnum | string;

export interface FeatureFlagStrategyScope {
  id: number;
  environment_scope: EnvironmentScope;
}

export interface FeatureFlagStrategy {
  id: number;
  name: string;
  parameters: Record<string, unknown>;
  scopes: FeatureFlagStrategyScope[];
}

export interface FeatureFlag {
  name: string;
  description: string;
  active: boolean;
  version: string;
  created_at: string;
  updated_at: string;
  scopes: any[];
  strategies: FeatureFlagStrategy[];
}

export type TransformedFeatureFlag = Pick<
  FeatureFlag,
  'name' | 'active' | 'scopes' | 'strategies'
>;

export type FeatureFlagsDictionary = Record<string, TransformedFeatureFlag>;
export type FeatureFlagApiResponse = FeatureFlag[];
